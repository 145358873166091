import { Box, Button, Typography } from "@mui/material";
import {
  GridFilterModel,
  GridLogicOperator,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { checkFilterActiveState } from "../utils/filters";

import CancelIcon from "@mui/icons-material/Cancel";

interface ActiveFilters {
  cashReceived: boolean;
  cashMissing: boolean;
  tooMuchCash: boolean;
  nextMonth: boolean;
}

const FilterToolbar = () => {
  const apiRef = useGridApiContext();

  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({
    cashReceived: false,
    cashMissing: false,
    tooMuchCash: false,
    nextMonth: false,
  });

  useEffect(() => {
    const updateActiveFilters = (params: GridFilterModel) => {
      const { items } = params;
      const filters = checkFilterActiveState(items);
      setActiveFilters(filters);
    };

    const unsubscribe = apiRef.current.subscribeEvent("filterModelChange", updateActiveFilters);
    return () => unsubscribe();
  }, [apiRef]);

  const handleCashReceivedFilter = () => {
    const cashReceivedFilter = {
      items: [
        {
          field: "matched",
          operator: "equals",
          value: "true",
          id: uuidv4(),
        },
        {
          field: "resolved",
          operator: "equals",
          value: "true",
          id: uuidv4(),
        },
      ],
      logicOperator: GridLogicOperator.Or,
    };
    apiRef.current.setFilterModel(cashReceivedFilter, "upsertFilterItems");
  };

  const handleCashMissingFilter = () => {
    const cashMissingFilter = {
      items: [
        {
          field: "error_status",
          operator: "equals",
          value: "true",
          id: uuidv4(),
        },
        {
          field: "rpt_status",
          operator: "equals",
          value: "success",
          id: uuidv4(),
        },
        {
          field: "et_status",
          operator: "equals",
          value: "failed",
          id: uuidv4(),
        },
      ],
      logicOperator: GridLogicOperator.And,
    };
    apiRef.current.setFilterModel(cashMissingFilter, "upsertFilterItems");
  };

  const handleTooMuchCashFilter = () => {
    const tooMuchCashFilter = {
      items: [
        {
          field: "error_status",
          operator: "equals",
          value: "true",
          id: uuidv4(),
        },
        {
          field: "rpt_status",
          operator: "equals",
          value: "failed",
          id: uuidv4(),
        },
        {
          field: "et_status",
          operator: "equals",
          value: "success",
          id: uuidv4(),
        },
      ],
      logicOperator: GridLogicOperator.And,
    };
    apiRef.current.setFilterModel(tooMuchCashFilter, "upsertFilterItems");
  };

  const handleNextMonthFilter = () => {
    const nextMonthFilter = {
      items: [
        {
          field: "vendor_missing_month_end",
          operator: "equals",
          value: "true",
          id: uuidv4(),
        },
      ],
    };

    apiRef.current.setFilterModel(nextMonthFilter, "upsertFilterItem");
  };

  const clearFilter = () => {
    apiRef.current.setFilterModel({ items: [] }, "deleteFilterItem");
  };

  return (
    <GridToolbarContainer
      sx={{ display: "flex", alignContent: "start", flexDirection: "column", gap: 0 }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 0.5,
          alignContent: "start",
          mb: 1,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", gap: 0.5, flexDirection: "column" }}>
          <Typography variant="h6" fontSize="10px">
            Filters
          </Typography>
          <Box
            sx={{ display: "flex", gap: 0.5, borderRight: "1px solid rgba(0, 0, 0, 0.12)", pr: 1 }}
          >
            <GridToolbarFilterButton />
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 0.5, flexDirection: "column", pl: 1 }}>
          <Typography variant="h6" fontSize="10px">
            Predefined
          </Typography>
          <Box
            sx={{ display: "flex", gap: 0.5, borderRight: "1px solid rgba(0, 0, 0, 0.12)", pr: 1 }}
          >
            <Button
              size="small"
              onClick={handleCashReceivedFilter}
              sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", padding: "3px 9px" }}
              variant={activeFilters.cashReceived ? "outlined" : "text"}
            >
              Cash received filter
            </Button>
            <Button
              size="small"
              sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", padding: "3px 9px" }}
              onClick={handleTooMuchCashFilter}
              variant={activeFilters.tooMuchCash ? "outlined" : "text"}
            >
              Too much cash filter
            </Button>
            <Button
              size="small"
              sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", padding: "3px 9px" }}
              onClick={handleCashMissingFilter}
              variant={activeFilters.cashMissing ? "outlined" : "text"}
            >
              Cash missing filter
            </Button>
            <Button
              size="small"
              sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", padding: "3px 9px" }}
              onClick={handleNextMonthFilter}
              variant={activeFilters.nextMonth ? "outlined" : "text"}
            >
              Expect this cash next period filter
            </Button>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 0.5, flexDirection: "column", pl: 1 }}>
          <Typography variant="h6" fontSize="10px" sx={{ visibility: "hidden" }}>
            Clear
          </Typography>

          <Button
            sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", padding: "3px 9px" }}
            startIcon={<CancelIcon sx={{ color: "rgba(0, 0, 0, 0.4)" }} fontSize="small" />}
            size="small"
            onClick={clearFilter}
          >
            Clear
          </Button>
        </Box>
      </Box>
    </GridToolbarContainer>
  );
};

export default FilterToolbar;
