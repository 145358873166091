import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import TaskEvent from "./TaskEvent";
import { useTask } from "../hooks/useTask";

const TaskTimeline: React.FC = () => {
  const { selectedTaskWithEvents, addComment } = useTask();
  const [comment, setComment] = useState("");
  const [isAddingComment, setIsAddingComment] = useState(false);

  useEffect(() => {
    if (!isAddingComment) {
      setComment("");
    }
  }, [isAddingComment]);

  if (!selectedTaskWithEvents) return null;

  const { task, events } = selectedTaskWithEvents;

  const handleAddCommentClick = () => {
    if (isAddingComment) {
      handleSubmitComment();
    } else {
      setIsAddingComment(true);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleSubmitComment = async () => {
    if (!comment.trim()) return;
    try {
      await addComment(task.uuid, comment);
      console.log("Comment submitted successfully");
    } catch (error) {
      console.error("Failed to submit comment:", error);
    } finally {
      setIsAddingComment(false);
    }
  };

  return (
    <Box>
      <Typography
        variant="body1"
        fontWeight={700}
        fontSize={12}
        gutterBottom
        sx={{ marginBottom: 2 }}
      >
        Task Timeline
      </Typography>
      <Box sx={{ position: "relative" }}>
        {events.map((event, index) => (
          <TaskEvent key={event.uuid} event={event} isLast={index === events.length - 1} />
        ))}
      </Box>
      {isAddingComment && (
        <TextField
          value={comment}
          onChange={handleInputChange}
          placeholder="Enter your comment"
          fullWidth
          multiline
          rows={3}
          sx={{ mt: 2 }}
        />
      )}
      <Button variant="outlined" sx={{ mt: 2 }} onClick={handleAddCommentClick}>
        {isAddingComment ? "Submit Comment" : "Add Comment"}
      </Button>
    </Box>
  );
};

export default TaskTimeline;
