import {
  getConnectedEdges,
  Handle,
  useNodeId,
  useStore as useStoreReactflow,
  useUpdateNodeInternals,
} from "@xyflow/react";
import { useCallback, useState } from "react";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import { useShallow } from "zustand/react/shallow";
import { Box, Tooltip } from "@mui/material";
import AddPlaceholder from "../components/AddPlaceholder";
import { ReactComponent as AddFilterIcon } from "../../../../assets/icons/workflowsAddIcon.svg";
import { isConnectable } from "../utils/edges";

const selector = createRFSelector("edges");

const AddPlaceholderHandle = props => {
  const { showIcon = false, parentId, ...rest } = props;
  const [open, setOpen] = useState(false);
  const { edges } = useStore(useShallow(selector));
  const { nodeLookup } = useStoreReactflow(useShallow(state => ({ nodeLookup: state.nodeLookup })));
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();

  const isHandleConnectable = useCallback(() => {
    if (typeof props.isConnectable === "function") {
      const node = nodeLookup.get(nodeId);
      const connectedEdges = getConnectedEdges([node], edges);
      updateNodeInternals(nodeId);
      return props.isConnectable({ node, connectedEdges });
    }

    if (typeof props.isConnectable === "number") {
      const node = nodeLookup.get(nodeId);
      const connectedEdges = getConnectedEdges([node], edges);
      updateNodeInternals(nodeId);
      return connectedEdges.length < props.isConnectable;
    }

    return props.isConnectable;
  }, [nodeLookup, edges, nodeId, props.isConnectable, updateNodeInternals]);

  const node = nodeLookup.get(nodeId);
  const isConnected = isConnectable(node, edges);
  // console.log("isConnected", isConnected);

  return (
    <Handle
      {...rest}
      isConnectable={isHandleConnectable}
      style={{ zIndex: 1, visibility: !isConnected ? "visible" : "hidden" }}
    >
      {isConnected && (
        <Tooltip title="Add new node" placement="top">
          <Box sx={{ visibility: "visible", cursor: "pointer" }}>
            <Box
              sx={{
                position: "relative",
                left: "-7px",
                top: "-7px",
                visibility: "visible",
              }}
              onClick={() => setOpen(true)}
            >
              <AddFilterIcon />
            </Box>

            <AddPlaceholder parentId={parentId} openHook={{ open, setOpen }} />
          </Box>
        </Tooltip>
      )}
    </Handle>
  );
  // return <Handle {...props} isConnectable={isHandleConnectable} style={{ zIndex: 1 }}></Handle>;
};

export default AddPlaceholderHandle;
