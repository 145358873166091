import { Edge, Node } from "@xyflow/react";

export const createEdge = (
  source: string,
  target: string,
  sourceHandle: string | null = null,
  targetHandle: string | null = null
): Edge => {
  return {
    id: `${source}=>${target}`,
    source,
    target,
    ...(targetHandle && { targetHandle }),
    ...(sourceHandle && { sourceHandle }),
  };
};

export const isConnectable = (node: Node, edges: Edge[]): boolean => {
  const { id, type } = node;
  const connectedEdges = edges.filter(edge => edge.source === id || edge.target === id);

  let allowedEdgeCount: number;

  switch (type) {
    case "dataMatchingSource":
      allowedEdgeCount = 1;
      break;
    case "filterNodeRoot":
      allowedEdgeCount = 3;
      break;
    case "filterNodeSecondary":
      allowedEdgeCount = 3;
      break;
    case "filterNodeRemaining":
      allowedEdgeCount = 2;
      break;
    case "xeroExportTarget":
      allowedEdgeCount = 1;
      break;
    case "sheetsExportTarget":
    default:
      allowedEdgeCount = 1;
      break;
  }

  return connectedEdges.length < allowedEdgeCount;
};
