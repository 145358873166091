import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { JSX, useEffect, useState } from "react";

export type UseConfirmReturn = [() => JSX.Element, () => Promise<any>];

const useConfirm = (title: string, message: string): UseConfirmReturn => {
  const [open, setOpen] = useState(false);
  const [resolveFn, setResolveFn] = useState(null);

  const confirm = () =>
    new Promise((resolve, reject) => {
      setOpen(true);
      setResolveFn(() => resolve);
    });

  const handleConfirm = () => {
    resolveFn(true);
    setOpen(false);
  };

  const handleCancel = () => {
    resolveFn(false);
    setOpen(false);
  };

  const ConfirmationDialog = () => {
    return (
      <Dialog open={open} onClose={handleCancel}>
        <Box sx={{ width: "312px", p: 2 }}>
          <DialogTitle sx={{ mb: 2, p: 0 }}>{title}</DialogTitle>
          <DialogContent sx={{ p: 0, mb: 5 }}>
            <Typography>{message}</Typography>
          </DialogContent>
          <DialogActions sx={{ p: 0, justifyContent: "flex-start" }}>
            <Button variant="contained" onClick={handleConfirm}>
              Yes
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  };

  return [() => <ConfirmationDialog />, confirm];
};

export default useConfirm;
