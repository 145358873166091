import { CheckboxProps, Checkbox as MuiCheckbox } from "@mui/material";

/**
 * @interface ICheckbox
 * @property {boolean} checked The checked state of the checkbox
 */
interface ICheckbox extends CheckboxProps {
  checked: boolean;
}
const Checkbox = (props: ICheckbox) => {
  const { checked, ...rest } = props;

  return (
    <MuiCheckbox
      size="small"
      sx={{
        color: checked ? "#142E90" : "rgba(0, 0, 0, 0.4)",
        "&.Mui-checked": {
          color: "#142E90",
        },
      }}
      checked={checked}
      {...rest}
    />
  );
};

export default Checkbox;
