import { NodeProps } from "@xyflow/react";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import useStore, { createRFSelector } from "../store/ReactflowStore";

const selector = createRFSelector("nodes", "setNodes", "getNode", "setEdges", "edges");
export function useDeleteGroupClick(id: NodeProps["id"]) {
  const { getNode, setNodes, setEdges, nodes, edges } = useStore(useShallow(selector));

  const onClick = useCallback(() => {
    const currentNode = getNode(id);

    if (!currentNode) {
      return;
    }

    const newNodes = nodes;
    const groupNodeIndex = nodes.findIndex(node => node.id === id);

    const filteredNodes = newNodes.filter((item, index) => {
      if (index >= groupNodeIndex || item.parentId === currentNode.id) {
        return false;
      }
      return true;
    });
    const newEdges = edges.filter(edge => edge.source !== id && edge.target !== id);

    setNodes(filteredNodes);
    setEdges(newEdges);
  }, [nodes, edges]);

  return onClick;
}

export default useDeleteGroupClick;
