import { GridFilterItem } from "@mui/x-data-grid";

export const checkFilterActiveState = (items: GridFilterItem[]) => {
  let cashReceived = false;
  let cashMissing = false;
  let tooMuchCash = false;
  let nextMonth = false;

  cashReceived = items.some(
    item => (item.field === "matched" || item.field === "resolved") && item.value === "true"
  );

  cashMissing =
    items.some(item => item.field === "error_status" && item.value === "true") &&
    items.some(item => item.field === "rpt_status" && item.value === "success") &&
    items.some(item => item.field === "et_status" && item.value === "failed");

  tooMuchCash =
    items.some(item => item.field === "error_status" && item.value === "true") &&
    items.some(item => item.field === "rpt_status" && item.value === "failed") &&
    items.some(item => item.field === "et_status" && item.value === "success");

  nextMonth = items.some(
    item => item.field === "vendor_missing_month_end" && item.value === "true"
  );

  return {
    cashReceived,
    cashMissing: cashMissing && !tooMuchCash,
    tooMuchCash: tooMuchCash && !cashMissing,
    nextMonth,
  };
};
