import ListAltIcon from "@mui/icons-material/ListAlt";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as ReiterateLogoBlue } from "../../assets/icons/logoLargeBlue.svg";
import CreateWorkflowModal from "../../components/Dialogs/Workflows/CreateWorkflowDialog";
import AppSidebar from "../../components/Sidebar/AppSidebar";
import CustomTabPanel from "../../components/Tabs/CustomTabPanel";
import { useBoApiClient } from "../../hooks/useBoApiClient";
import TasksView from "./Tasks/TasksView";
import { TaskProvider } from "./Tasks/contexts/TaskContext";
import WorkflowsItemView from "./Workflows/WorkflowsItemView";
import WorkflowsView from "./Workflows/WorkflowsView";
import { initialEdges } from "./Workflows/store/InitialEdges";
import { initialNodes } from "./Workflows/store/InitialNodes";
import { WorkflowDto } from "../../spec/bo";

const WorkflowView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const uuid = searchParams.get("workflow");
  const [createWorkflowModelOpen, setCreateWorkflowModelOpen] = useState<boolean>(false);
  const tabValue = uuid ? 1 : 0;
  const [value, setValue] = useState<Number>(tabValue);
  const boApiClient = useBoApiClient();
  const [workflows, setWorkflows] = useState<WorkflowDto[]>([]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchWorkflows = async () => {
    try {
      const response: WorkflowDto[] = await await boApiClient.workflows.getWorkflows();
      const sortedWorkflows = response.sort((a, b) => {
        if (!a.updated_at || !b.updated_at) return 0;
        return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
      });
      setWorkflows(sortedWorkflows);
    } catch (e) {
      console.log(e);
    }
  };

  const createWorkflow = async name => {
    try {
      await boApiClient.workflows.createWorkflow({
        definition: {
          nodes: initialNodes,
          edges: initialEdges,
        },
        name,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
        flex: 1,
        overflow: "hidden",
      }}
    >
      {createWorkflowModelOpen && (
        <CreateWorkflowModal
          success={async data => {
            try {
              await createWorkflow(data);
            } catch (e) {
            } finally {
              setCreateWorkflowModelOpen(false);
              fetchWorkflows();
            }
          }}
          cancel={() => setCreateWorkflowModelOpen(false)}
        />
      )}

      <AppSidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          overflowY: "auto",
        }}
      >
        <Box
          id="workflows-container"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", borderBottom: "0.5px solid #dcdcdc" }}>
            <Box>
              <Box sx={{ py: 2, display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                  <ReiterateLogoBlue width="116px" style={{ margin: "0 20px 0 24px" }} />
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{
                      minHeight: "unset",
                      "& .MuiTabs-flexContainer": {
                        display: "flex",
                        alignItems: "center !important",
                        maxHeight: "32px",
                      },
                      ".MuiTab-iconWrapper": {
                        marginBottom: "0 !important",
                        marginRight: "4px",
                      },
                      ".MuiTab-root": {
                        borderRadius: "24px !important",
                      },
                      ".Mui-selected": {
                        color: `#142E90 !important`,
                        backgroundColor: "#EEF1FB",

                        minHeight: "unset",
                      },
                    }}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Tab
                      label="Task view"
                      sx={{
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        height: "32px",
                      }}
                      icon={<ListAltIcon fontSize="small" />}
                    />
                    <Tab
                      label="Workflows"
                      sx={{
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        height: "32px",
                      }}
                      icon={<SpaceDashboardIcon fontSize="small" />}
                    />
                  </Tabs>
                  <Button onClick={() => setCreateWorkflowModelOpen(true)}>Create workflow</Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <TaskProvider>
              <TasksView />
            </TaskProvider>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {uuid && <WorkflowsItemView />}
            {!uuid && <WorkflowsView workflowHook={{ workflows, fetchWorkflows }} />}
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkflowView;
