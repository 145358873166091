import { TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableRow = styled(TableRow)(() => ({
  borderBottom: "1px solid lightgrey",
  height: "40px",
  "&:last-child": {
    borderBottom: 0,
  },
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    cursor: "pointer",
  },
  transition: "background-color 0.3s ease",
}));

export default StyledTableRow;
