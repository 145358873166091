import { createContext } from "react";

interface UIContextInterface {
  isSourceModalFlowOpen: boolean;
  setSourceModalFlowOpen: (value: boolean) => void;
  dataViewNodeId: string;
  setDataViewNodeId: (value: string) => void;
  toolVisibilitySettings: ToolVisibilitySettings;
  setToolVisibilitySettings: (value: object) => void;
}

interface ToolVisibilitySettings {
  matching_tool: boolean;
  reiterate?: boolean;
  looker_explore?: boolean;
  looker_dashboard?: boolean;
  settlement_tool: boolean;
  fee_analysis?: boolean;
  estimation_model_settings?: boolean;
  workflows?: boolean;
}

const UIContext = createContext<UIContextInterface | null>(null);

export default UIContext;
