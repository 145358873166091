import { Box, Typography } from "@mui/material";
import { Position } from "@xyflow/react";
import { formatNumberWithCommas, formatNumberWithoutCommas } from "../../../../core/utils";
import { DataSourceNodeTable, FilterTableRow } from "../components/ReactflowElements";

import SingleConnectionHandle from "../handleTypes/Handle";
import SingleConnectionIconHandle from "../handleTypes/AddPlaceholderHandle";

const FilterNodeRemaining = props => {
  const { data, isConnectable, id } = props;
  const consolidatedValues = data?.summary?.headers.map((header: any) => {
    const value = data.summary.rows[0][header.key];
    return {
      key: header.value,
      description: header.value,
      value: value,
    };
  });

  return (
    <>
      <SingleConnectionHandle
        type="target"
        position={Position.Top}
        id="groupContinuationTarget"
        isConnectable={1}
      />
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.12)",
          p: 2,
          width: "352px",
          backgroundColor: "white",
          borderRadius: "4px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" fontSize="12px" fontWeight="700">
            {data?.label}
            {/* {id} */}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 0.5, mt: 0.5, flexWrap: "wrap" }}></Box>
        <DataSourceNodeTable>
          <FilterTableRow>
            {consolidatedValues &&
              consolidatedValues.map((item, index) => {
                return (
                  <Typography variant="body1" fontSize="12px" key={index}>
                    {item.description}
                  </Typography>
                );
              })}
            {!consolidatedValues && (
              <>
                <Typography variant="body1" fontSize="12px">
                  Transaction %
                </Typography>
                <Typography variant="body1" fontSize="12px">
                  Count
                </Typography>
                <Typography variant="body1" fontSize="12px">
                  Amount EUR
                </Typography>
              </>
            )}
          </FilterTableRow>

          <FilterTableRow>
            {consolidatedValues &&
              consolidatedValues.map((item, index) => {
                const fontWeight = item.key === "Transaction %" ? "700" : "400";
                return (
                  <Typography variant="body1" fontSize="12px" fontWeight={fontWeight} key={index}>
                    {item.key === "amount_eur"
                      ? formatNumberWithCommas(item.value)
                      : formatNumberWithoutCommas(item.value)}
                  </Typography>
                );
              })}
            {!consolidatedValues && (
              <>
                <Typography variant="body1" fontSize="12px" fontWeight={700}>
                  0%
                </Typography>
                <Typography variant="body1" fontSize="12px" fontWeight={700}>
                  0
                </Typography>
                <Typography variant="body1" fontSize="12px" fontWeight={700}>
                  0
                </Typography>
              </>
            )}
          </FilterTableRow>
        </DataSourceNodeTable>
      </Box>

      <SingleConnectionIconHandle
        type="source"
        parentId={id}
        position={Position.Right}
        id="continuationSource"
        isConnectable={isConnectable}
      />
    </>
  );
};

export default FilterNodeRemaining;
