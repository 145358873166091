import {
  getConnectedEdges,
  Handle,
  useNodeId,
  useStore as useStoreReactFlow,
  useUpdateNodeInternals,
} from "@xyflow/react";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import useStore, { createRFSelector } from "../store/ReactflowStore";

const selector = createRFSelector("edges");

const SingleConnectionHandle = props => {
  const { edges } = useStore(useShallow(selector));
  const { nodeLookup } = useStoreReactFlow(useShallow(state => ({ nodeLookup: state.nodeLookup })));
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();

  const isHandleConnectable = useCallback(() => {
    if (typeof props.isConnectable === "function") {
      const node = nodeLookup.get(nodeId);
      const connectedEdges = getConnectedEdges([node], edges);
      updateNodeInternals(nodeId);
      return props.isConnectable({ node, connectedEdges });
    }

    if (typeof props.isConnectable === "number") {
      const node = nodeLookup.get(nodeId);
      const connectedEdges = getConnectedEdges([node], edges);
      updateNodeInternals(nodeId);
      return connectedEdges.length < props.isConnectable;
    }

    return props.isConnectable;
  }, [nodeLookup, edges, nodeId, props.isConnectable, updateNodeInternals]);

  return <Handle {...props} isConnectable={isHandleConnectable} style={{ zIndex: 1 }}></Handle>;
};

export default SingleConnectionHandle;
