import React from "react";
import { Box, Typography } from "@mui/material";
import DotSeperator from "../../../../components/Dividers/DotSeperator";

const TasksHeader = ({ period }) => {
  return (
    <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
      <Typography variant="h6" fontSize={14} fontWeight="bold">
        Tasks
      </Typography>
      <DotSeperator />
      <Typography variant="h6" fontSize={14} fontWeight="bold">
        {period}
      </Typography>
    </Box>
  );
};

export default TasksHeader;
