/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkflowDto } from '../models/WorkflowDto';
import type { WorkflowRunDto } from '../models/WorkflowRunDto';
import type { WorkflowUpdateDto } from '../models/WorkflowUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WorkflowsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns WorkflowDto Success
   * @throws ApiError
   */
  public getWorkflows(): CancelablePromise<Array<WorkflowDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows',
    });
  }

  /**
   * @param requestBody
   * @returns WorkflowDto Success
   * @throws ApiError
   */
  public createWorkflow(
    requestBody?: WorkflowUpdateDto,
  ): CancelablePromise<WorkflowDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/workflows',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param uuid
   * @returns WorkflowDto Success
   * @throws ApiError
   */
  public getWorkflow(
    uuid: string,
  ): CancelablePromise<WorkflowDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows/{uuid}',
      path: {
        'uuid': uuid,
      },
    });
  }

  /**
   * @param uuid
   * @param requestBody
   * @returns WorkflowDto Success
   * @throws ApiError
   */
  public updateWorkflow(
    uuid: string,
    requestBody?: WorkflowUpdateDto,
  ): CancelablePromise<WorkflowDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/workflows/{uuid}',
      path: {
        'uuid': uuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param uuid
   * @returns WorkflowRunDto Success
   * @throws ApiError
   */
  public runWorkflow(
    uuid: string,
  ): CancelablePromise<WorkflowRunDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/workflows/{uuid}/run',
      path: {
        'uuid': uuid,
      },
    });
  }

  /**
   * @param uuid
   * @returns any Success
   * @throws ApiError
   */
  public deleteWorkflow(
    uuid: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/workflows/{uuid}/delete',
      path: {
        'uuid': uuid,
      },
    });
  }

}
