import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import StyledAvatar from "../../../../components/Styled/StyledAvatar";
import robotIcon from "../../../../assets/icons/robotIcon.svg";
import { TaskDto } from "../../../../spec/bo";
import { createAvatarContent } from "../utils/taskCellUtils";

interface TaskDetailsPreparerRowProps {
  task: TaskDto;
}

const TaskDetailsPreparerRow: React.FC<TaskDetailsPreparerRowProps> = ({ task }) => {
  const displayName = task.is_automatic
    ? "Automatic"
    : (task.can_prepare ? task.preparer?.name : "User") || "User";

  return (
    <Grid container spacing={3} sx={{ height: 40, pb: 6 }} alignItems="center">
      <Grid item xs={2}>
        <Typography fontSize={12} variant="body2" color="rgba(0, 0, 0, 0.6)">
          Preparer
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Grid container direction="row" alignItems="center">
          <StyledAvatar>
            {createAvatarContent(
              task.is_automatic,
              task.preparer?.name ?? "User",
              task.can_prepare,
              <img alt="A" src={robotIcon} width="16.6px" height="16.6px" />
            )}
          </StyledAvatar>
          <Typography
            variant="body2"
            noWrap
            fontSize={12}
            lineHeight={1.6}
            sx={{
              ml: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {displayName}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
          {/*<StyledSwitch*/}
          {/*  checked={true}*/}
          {/*  onChange={() => {*/}
          {/*    console.log(123);*/}
          {/*  }}*/}
          {/*  disabled={true}*/}
          {/*/>*/}
        </Box>
      </Grid>
    </Grid>
  );
};

export default TaskDetailsPreparerRow;
