import { Node } from "@xyflow/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const initialNodes = [
  {
    id: "root-data-source",
    type: "dataMatchingSource",
    data: {
      label: "dataMatchingSource",
      date_range: {
        end_date: dayjs().utc(false).startOf("month").toISOString(),
        start_date: dayjs().utc(false).endOf("month").toISOString(),
      },
    },
    position: { x: 0, y: 0 },
    initialWidth: 448,
    initialHeight: 193,
  },
] as Node[];
