import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

type ChipType = "grey" | "blue" | "green";

interface StyledChipProps {
  type: ChipType;
  label: string;
}

const StyledChip: React.FC<StyledChipProps> = ({ type, label }) => {
  const ChipComponent = styled(Chip)(() => ({
    borderRadius: "6px",
    height: "23px",
    padding: "2px",
    "& .MuiChip-label": {
      padding: "0 6px",
    },
    ...(type === "grey" && {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.6)",
    }),
    ...(type === "blue" && {
      backgroundColor: "#EEF1FB",
      border: "1px solid #BCC8F5",
      color: "#1D42C9",
    }),
    ...(type === "green" && {
      backgroundColor: "#EFF8EA",
      border: "1px solid #97CB8F",
      color: "#3F743F",
    }),
  }));

  return <ChipComponent label={label} />;
};

export default StyledChip;
