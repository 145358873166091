import { Edge, Node, NodeProps } from "@xyflow/react";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import { createEdge } from "../utils/edges";
import {
  FILTER_NODE_TYPES,
  GAP_BETWEEN_NODES,
  GROUPED_NODE_INITIAL_WIDTH,
  GROUPED_NODE_PADDING_POSITION,
  NodePosition,
  createGroupNode,
  createRemainingTxNode,
  createRootFilterNode,
  getNodeInitialWidth,
} from "../utils/nodes";

const selector = createRFSelector("nodes", "edges", "setNodes", "setEdges", "getNode");

export function useFilterClick(id: NodeProps["id"]) {
  const { nodes, edges, setNodes, setEdges, getNode } = useStore(useShallow(selector));

  const onClick = useCallback(() => {
    let currentNode = nodes.find(node => node.id === id);
    const incomers: Node = getNode(id);

    if (!currentNode) {
      return;
    }
    if (FILTER_NODE_TYPES.includes(currentNode.type)) {
      currentNode = getNode(currentNode.parentId);
    }

    const groupNodePosition = {
      x: currentNode.position.x + currentNode.measured.width + GAP_BETWEEN_NODES,
      y: incomers.position.y,
    };
    const groupNode: Node = createGroupNode(groupNodePosition);
    const rootFilterPosition: NodePosition = {
      x: GROUPED_NODE_PADDING_POSITION,
      y: 50,
    };

    const rootFilterNode: Node = createRootFilterNode(groupNode.id, rootFilterPosition);

    const remainingTxNodePosition: NodePosition = {
      x: GROUPED_NODE_PADDING_POSITION,
      y: rootFilterNode.position.y + rootFilterNode.initialHeight + GAP_BETWEEN_NODES,
    };
    const remainingTxNode: Node = createRemainingTxNode(groupNode.id, remainingTxNodePosition);

    const incomerToFilterEdge: Edge = createEdge(
      incomers.id,
      rootFilterNode.id,
      "continuationSource",
      "continuationTarget"
    );
    const rootToRemainingEdge: Edge = createEdge(
      rootFilterNode.id,
      remainingTxNode.id,
      "groupContinuationSource",
      "groupContinuationTarget"
    );

    const currentIndex = nodes.findIndex(node => node.id === id);

    const finalNodes = [...nodes];
    finalNodes.splice(currentIndex + 1, 0, groupNode, rootFilterNode, remainingTxNode);

    setNodes(finalNodes);

    const newEdges = edges.concat([incomerToFilterEdge, rootToRemainingEdge]);
    setEdges(newEdges);
  }, [nodes, edges]);

  return onClick;
}

export default useFilterClick;
