import { Edge, Node, NodeProps } from "@xyflow/react";
import { useShallow } from "zustand/react/shallow";
import useStore, { createRFSelector } from "../store/ReactflowStore";

const selector = createRFSelector(
  "nodes",
  "setNodes",
  "getNode",
  "setEdges",
  "edges",
  "getIncomers"
);

export function useDeleteExportClick(id: NodeProps["id"]) {
  const { getNode, setNodes, setEdges, nodes, edges, getIncomers } = useStore(useShallow(selector));

  const onClick = () => {
    const currentNode = getNode(id);

    if (!currentNode) {
      return;
    }
    const newEdges: Edge[] = edges.filter(edge => edge.source !== id && edge.target !== id);
    const newNodes: Node[] = nodes.filter(node => node.id !== id);
    setNodes(newNodes);
    setEdges(newEdges);
  };

  return onClick;
}

export default useDeleteExportClick;
