import EditLineIcon from "@mui/icons-material/BorderColor";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { NodeProps, Position } from "@xyflow/react";
import { useCallback, useEffect, useState } from "react";
import { useBoApiClient } from "../../../../hooks/useBoApiClient";
import useConfirm from "../../../../hooks/useConfirm";
import { ConnectionDto, GetUserDto, XeroTenantDto } from "../../../../spec/bo";
import Export, { IExportSettings } from "../components/ExportToXero";
import {
  DataSourceNodeTable,
  DataSourceNodeTableRowTwo,
  FilterChip,
} from "../components/ReactflowElements";
import Sidebar from "../components/Sidebar";
import SingleConnectionHandle from "../handleTypes/Handle";
import useDeleteExportClick from "../hooks/useDeleteExportClick";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import { useShallow } from "zustand/react/shallow";

interface IExportProps extends NodeProps {
  id: string;
  data: {
    label: string;
    exportSettings: IExportSettings;
  };
}

const selector = createRFSelector("hoveredNode");

const ExportTargetXero = (props: IExportProps) => {
  const { data, id } = props;
  const { exportSettings } = data;
  const { hoveredNode } = useStore(useShallow(selector));
  const isHovered = hoveredNode && hoveredNode.id === id;

  const [users, setUsers] = useState<GetUserDto[]>([]);
  const [organizations, setOrganizations] = useState<XeroTenantDto[]>([]);
  const [integration, setIntegration] = useState<ConnectionDto | null>(null);

  const boApiClient = useBoApiClient();
  const [open, setOpen] = useState(false);
  const [ConfirmDialog, confirmDelete] = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete the export node?"
  );

  const settingsMap = {
    period: "Export interval",
    taskPreparers: "Preparer",
    taskReviewers: "Reviewer",
  };

  const ExportChip = ({ values, isAutomatic, string }) => {
    if (isAutomatic) return <FilterChip label={`${string}: Automatic`} />;
    if (Array.isArray(values)) {
      if (values.length === 1) {
        const user = users.find(user => user.uuid === values[0]);
        return <FilterChip label={`${string}: ${user?.email}`} />;
      } else {
        return <FilterChip label={`${string}: ${values.length} people`} />;
      }
    }
    return <></>;
  };

  const getIntegrations = async () => {
    const [integration] = await boApiClient.connections
      .getConnections()
      .then(res => res.filter(integration => integration.connection_type === "xero"));

    setIntegration(integration);
  };

  const fetchOrganizations = useCallback(async () => {
    try {
      const orgs = await boApiClient.xeroIntegrations.getTenants();
      console.log(orgs);
      setOrganizations(orgs);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, [boApiClient]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  const onDelete = useDeleteExportClick(id);

  const handleDelete = async () => {
    const confirm = await confirmDelete();
    if (confirm) onDelete();
  };

  const fetchUsers = useCallback(async () => {
    try {
      const users = await boApiClient.users.getUsers();
      setUsers(users);
    } catch (e) {
      console.error(e);
    } finally {
    }
  }, [id]);

  useEffect(() => {
    getIntegrations();
    fetchUsers();
  }, []);

  return (
    <div>
      <ConfirmDialog />
      <Sidebar openHook={{ open, setOpen }}>
        <Export
          id={id}
          data={data}
          integration={integration}
          users={users}
          organizations={organizations}
        />
      </Sidebar>
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.12)",
          p: "14px 20px",
          width: "352px",
          backgroundColor: "white",
          borderRadius: "4px",
          position: "relative",
        }}
      >
        <SingleConnectionHandle
          type="target"
          position={Position.Left}
          id="exportTarget"
          isConnectable={1}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 0.5, verticalAlign: "center" }}>
            <Typography variant="h5" fontSize="12px" fontWeight="700">
              {data?.label}
            </Typography>
          </Box>

          <Button
            variant="text"
            size="small"
            onClick={() => setOpen(true)}
            startIcon={<EditLineIcon />}
          >
            Edit
          </Button>
        </Box>

        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1,
            cursor: "pointer",
          }}
          onClick={handleDelete}
          size="small"
        >
          <CancelIcon fontSize="small" sx={{ color: "#BFBFBF" }} />
        </IconButton>

        <Box sx={{ display: "flex", gap: 0.5, mt: 0.5, flexWrap: "wrap" }}>
          {exportSettings?.period && (
            <FilterChip label={`${settingsMap.period}: ${exportSettings.period}`} />
          )}
          {exportSettings?.taskPreparers && (
            <ExportChip
              values={exportSettings.taskPreparers}
              isAutomatic={exportSettings.taskPreparerType === "automatic"}
              string="Task preprarer"
            />
          )}
          {exportSettings?.taskReviewers && (
            <ExportChip
              values={exportSettings.taskReviewers}
              isAutomatic={exportSettings.taskReviewerType === "automatic"}
              string="Task reviewer"
            />
          )}
        </Box>
        <DataSourceNodeTable>
          <DataSourceNodeTableRowTwo>
            <Typography variant="body1" fontSize="12px" fontWeight="400">
              Debit
            </Typography>
            <Typography variant="body1" fontSize="12px" fontWeight="400">
              {exportSettings?.debitAccount}
            </Typography>
          </DataSourceNodeTableRowTwo>
          <DataSourceNodeTableRowTwo>
            <Typography variant="body1" fontSize="12px" fontWeight="400">
              Credit
            </Typography>
            <Typography variant="body1" fontSize="12px" fontWeight="400">
              {exportSettings?.creditAccount}
            </Typography>
          </DataSourceNodeTableRowTwo>
        </DataSourceNodeTable>
      </Box>
    </div>
  );
};

export default ExportTargetXero;
