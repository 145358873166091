import React from "react";
import { Chip, ChipProps, SvgIconProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import AddIcon from "@mui/icons-material/Add";

type IconPosition = "start" | "end";

type IconOptions = "workflow" | "view" | "add";

interface StyledChipWithIconProps extends Omit<ChipProps, "icon" | "deleteIcon"> {
  label: string;
  iconType: IconOptions;
  iconPosition?: IconPosition;
}

const StyledChipWithIcon: React.FC<StyledChipWithIconProps> = ({
  label,
  iconType,
  iconPosition = "start",
  ...chipProps
}) => {
  const ChipComponent = styled(Chip)(({ theme }) => ({
    backgroundColor: "white",
    fontFamily: "ABCWhyte",
    lineHeight: 1,
    letterSpacing: "2%",
    fontWeight: 700,
    border: `1px solid #BCC8F5`,
    fontSize: 10,
    color: "#142E90",
    borderRadius: "4px",
    height: "22px",
    "& .MuiChip-label": {
      padding: "0 4px",
      lineHeight: "20px",
    },
    "& .MuiChip-icon, & .MuiChip-deleteIcon": {
      color: "#142E90",
      height: "16px",
      width: "16px",
      margin: "0 2px",
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const getIcon = (type: string): React.ReactElement<SvgIconProps> => {
    switch (type) {
      case "workflow":
        return <OpenInNewIcon />;
      case "view":
        return <ManageSearchIcon />;
      case "add":
        return <AddIcon />;
      default:
        return <></>;
    }
  };

  const icon = getIcon(iconType);

  return (
    <ChipComponent
      label={label}
      icon={iconPosition === "start" ? icon : undefined}
      deleteIcon={iconPosition === "end" ? icon : undefined}
      onDelete={iconPosition === "end" ? () => {} : undefined}
      {...chipProps}
    />
  );
};

export default StyledChipWithIcon;
