import dayjs from "dayjs";

const START_DATE = "2024-01-01";

export function generateMonths(
  startDate: string = START_DATE,
  endDate: string = dayjs().format("YYYY-MM-DD")
): string[] {
  let months: string[] = [];
  let currentDate = dayjs(endDate);
  const startMonth = dayjs(startDate);

  while (currentDate.isAfter(startMonth) || currentDate.isSame(startMonth, "month")) {
    months.push(currentDate.format("MMMM YYYY"));
    currentDate = currentDate.subtract(1, "month");
  }

  return months;
}
