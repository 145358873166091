import { MenuItemProps, MenuItem as MuiMenuItem } from "@mui/material";

/**
 * @interface IMenuItem
 * @property {string} value The value of the MenuItem
 */
interface IMenuItem extends MenuItemProps {
  value: string;
}
const MenuItem = (props: IMenuItem) => {
  const { value, children, ...rest } = props;

  return (
    <MuiMenuItem
      dense
      value={value}
      {...rest}
      sx={{
        borderRadius: "4px",
        px: 1,
        ".Mui-selected": {
          background: "#EEF1FB",
        },
        "&:hover": {
          background: "rgba(0, 0, 0, 0.04)",
        },
      }}
    >
      {children}
    </MuiMenuItem>
  );
};

export default MenuItem;
