import {
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnEdgesChange,
  OnNodesChange,
  applyEdgeChanges,
  applyNodeChanges,
  isEdge,
  isNode,
} from "@xyflow/react";
import { create } from "zustand";

type RFState = {
  nodes: Node[];
  edges: Edge[];
  hoveredNode: Node | null;
  setHoveredNode(node: Node): void;
  setNodes: (nodes: Node[]) => void;
  setEdges: (edges: Edge[]) => void;
  getNode: (id: string) => Node;
  getNodes: () => Node[];
  getEdges: () => Edge[];
  onNodesChange: (changes: NodeChange[], workflow: string) => void;
  onEdgesChange: OnEdgesChange;
  getIncomers: (node: Node) => Node[];
  getOutgoers: (node: Node) => Node[];
  updateAndRunWorkflow: Function | null;
  setUpdateAndRunWorkflow: (updateAndRunWorkflow: Function) => void;
  hasNodeChanged: (currentNodeId: string) => boolean;
  hasUnappliedChanges: boolean;
  setHasUnappliedChanges: (hasUnappliedChanges: boolean) => void;
  disabled: boolean;
  setDisabled: (disabled: boolean) => void;
};

export type SelectorType<K extends keyof RFState> = Pick<RFState, K>;

export function createRFSelector<K extends keyof RFState>(...keys: K[]) {
  return (state: RFState): SelectorType<K> => {
    return keys.reduce((acc, key) => {
      acc[key] = state[key];
      return acc;
    }, {} as SelectorType<K>);
  };
}

const getIncomers = (node, elements): Node[] => {
  if (!isNode(node)) {
    return [];
  }
  const incomersIds = elements
    .filter(item => isEdge(item) && item.target === node.id)
    .map(item => item.source);
  return elements.filter(item => incomersIds.includes(item.id) && isNode(item));
};

const getOutgoers = (node, elements): Node[] => {
  if (!isNode(node)) {
    return [];
  }
  const outgoersIds = elements
    .filter(item => isEdge(item) && item.source === node.id)
    .map(item => item.target);
  return elements.filter(item => outgoersIds.includes(item.id) && isNode(item));
};

const getNode = (id: string, elements: Node[]): Node => {
  return elements.find(node => node.id === id);
};

// Create a function that removes all edges that are connected to a node that no longer exists
const removeInvalidEdges = (edges: Edge[], nodes: Node[]): Edge[] => {
  const nodeIds = new Set(nodes.map(node => node.id));
  const validEdges = edges.filter(edge => nodeIds.has(edge.source) && nodeIds.has(edge.target));
  return validEdges;
};

const checkForNodeChanges = (currentNode: Node, workflow: string): boolean => {
  try {
    const parsedWorkflow = JSON.parse(workflow);
    const storedNode = parsedWorkflow.nodes.find(node => node.id === currentNode.id);
    console;
    if (storedNode) {
      console.log();
      console.log(
        "checkForNodeChanges",
        JSON.stringify(storedNode.data) !== JSON.stringify(currentNode.data)
      );
      return JSON.stringify(storedNode.data) !== JSON.stringify(currentNode.data);
    }
    return false;
  } catch (e) {
    console.log(e);
  }
  return false;
};

const checkForUnappliedChanges = (nodes: Node[], workflow: string): boolean => {
  try {
    const parsedWorkflow = JSON.parse(workflow);
    const storedNodes = parsedWorkflow.nodes;
    if (nodes.length !== storedNodes.length) {
      console.log("checkForUnappliedChanges changed lengths");
      return true;
    }
    console.log("checkForUnappliedChanges IS SAME LENGTH");

    return false;
  } catch (e) {
    console.log(e);
  }

  return false;
};

const useStore = create<RFState>((set, get) => ({
  nodes: [],
  edges: [],
  hoveredNode: null,
  setHoveredNode: (node: Node) => {
    set({ hoveredNode: node });
  },
  setNodes: (nodes: Node[]) => {
    console.log(nodes);
    set({ nodes });
  },
  setEdges: (edges: Edge[]) => {
    const cleanedEdges = removeInvalidEdges(edges, get().nodes);
    console.log(cleanedEdges);
    set({ edges: cleanedEdges });
  },
  getNode: (id: string): Node => {
    return getNode(id, get().nodes);
  },
  getNodes(): Node[] {
    return get().nodes;
  },
  getEdges(): Edge[] {
    return get().edges;
  },
  onNodesChange: (changes: NodeChange[], workflow: string) => {
    const unappliedChanges = checkForUnappliedChanges(get().nodes, workflow);
    console.log("unappliedChanges", unappliedChanges);
    if (unappliedChanges) {
      set({ hasUnappliedChanges: true });
    }
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  getIncomers(node): Node[] {
    return getIncomers(node, [...get().nodes, ...get().edges]);
  },
  getOutgoers(node): Node[] {
    return getOutgoers(node, [...get().nodes, ...get().edges]);
  },
  updateAndRunWorkflow: null,
  setUpdateAndRunWorkflow: (updateAndRunWorkflow: Function) => set({ updateAndRunWorkflow }),
  hasNodeChanged: (currentNodeId: string) => {
    const currentNode = get().nodes.find(node => node.id === currentNodeId);
    const workflow = window.localStorage.getItem("workflow");
    if (currentNode && workflow) {
      console.log(1);
      const changes = checkForNodeChanges(currentNode, workflow);
      if (changes) {
        console.log(2);
        console.log("hasNodeChanges changed hasUnappliedChanges");
        set({ hasUnappliedChanges: true });
        return true;
      }
      console.log(3);
      return false;
    }
    console.log(4);
    return false;
  },
  hasUnappliedChanges: false,
  setHasUnappliedChanges: (hasUnappliedChanges: boolean) => {
    set({ hasUnappliedChanges });
  },
  disabled: false,
  setDisabled: (disabled: boolean) => {
    set({ disabled });
  },
}));

export default useStore;
