import {
  Background,
  BackgroundVariant,
  Edge,
  Node,
  NodeChange,
  ProOptions,
  ReactFlow,
} from "@xyflow/react";

import edgeTypes from "./edgeTypes";
import nodeTypes from "./nodeTypes";

import { LoadingButton } from "@mui/lab";
import { Box, Chip, CircularProgress } from "@mui/material";
import "@xyflow/react/dist/base.css";
import "@xyflow/react/dist/style.css";
import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { WorkflowRunDto } from "../../../spec/bo/models/WorkflowRunDto";
import ReactFlowDevTools from "./devTools/DevTools";
import useStore, { createRFSelector } from "./store/ReactflowStore";
import { ReactFlowDto, WorkflowUpdateDto } from "../../../spec/bo";
import { useBoApiClient } from "../../../hooks/useBoApiClient";
import { useSnackbar } from "notistack";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../components/common/notifications";
import { FilterChipChanges } from "./components/ReactflowElements";

const proOptions: ProOptions = { account: "paid-pro", hideAttribution: true };

const selector = createRFSelector(
  "nodes",
  "edges",
  "setNodes",
  "setEdges",
  "onNodesChange",
  "onEdgesChange",
  "setHoveredNode",
  "updateAndRunWorkflow",
  "setUpdateAndRunWorkflow",
  "getNodes",
  "getEdges",
  "hasUnappliedChanges",
  "setHasUnappliedChanges",
  "setDisabled"
);

const WorkflowsItemView = () => {
  const [searchParams, _] = useSearchParams();

  const workflowUuid = searchParams.get("workflow");
  const boApiClient = useBoApiClient();
  const [loading, setLoading] = useState<{
    getWorkflow: boolean;
    runWorkflow: boolean;
    updateWorkflow: boolean;
  }>({
    getWorkflow: false,
    runWorkflow: false,
    updateWorkflow: false,
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const disabled = loading.getWorkflow || loading.runWorkflow || loading.updateWorkflow;
  const [name, setName] = useState<string>("");

  const {
    nodes,
    edges,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange,
    getNodes,
    getEdges,
    setDisabled,
    hasUnappliedChanges,
    setHasUnappliedChanges,
    setUpdateAndRunWorkflow,
  } = useStore(useShallow(selector));

  const runWorkflow = async (): Promise<WorkflowRunDto> => {
    try {
      setLoading({ ...loading, runWorkflow: true });
      return await boApiClient.workflows.runWorkflow(workflowUuid);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading({ ...loading, runWorkflow: false });
    }
  };

  const updateAndRunWorkflows = async (): Promise<void> => {
    try {
      setDisabled(true);
      await updateWorkflow();
      const result = await runWorkflow();
      if (result) {
        const nodes = enrichNodes(result);
        setNodes(nodes);
        await updateWorkflow();
        showSuccessNotification(`Run completed`, enqueueSnackbar, closeSnackbar);
      }
    } catch (e) {
      showErrorNotification(`Failed to run workflow`, enqueueSnackbar, closeSnackbar);
    } finally {
      setDisabled(false);
    }
  };

  const updateWorkflow = useCallback(async (): Promise<void> => {
    try {
      setLoading({ ...loading, updateWorkflow: true });
      const workflow: WorkflowUpdateDto = {
        name: name,
        definition: {
          nodes: getNodes(),
          edges: getEdges(),
        },
      };
      const { definition } = await boApiClient.workflows.updateWorkflow(workflowUuid, workflow);
      setNodes(definition.nodes);
      setEdges(definition.edges);
      addFiltersToSessionStorage(definition.nodes);
      storeWorkflowToStorage({
        nodes: definition.nodes,
        edges: definition.edges,
      });
      setHasUnappliedChanges(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading({ ...loading, updateWorkflow: false });
    }
  }, [workflowUuid, name, nodes, edges, loading]);

  const addFiltersToSessionStorage = (nodes: Node[]) => {
    return nodes.map(item => {
      if (item?.data?.filters_original) {
        window.sessionStorage.setItem(
          `filters-${item.id}`,
          JSON.stringify(item.data.filters_original)
        );
      }
    });
  };

  const getWorkflow = useCallback(async (): Promise<void> => {
    try {
      setLoading(prevLoading => ({ ...prevLoading, getWorkflow: true }));
      const response = await boApiClient.workflows.getWorkflow(workflowUuid);

      const { definition } = response;
      if (definition.nodes.length === 0) {
        return;
      }
      setName(response.name);
      setNodes(definition.nodes);
      setEdges(definition.edges);
      addFiltersToSessionStorage(definition.nodes);
      storeWorkflowToStorage({
        nodes: definition.nodes,
        edges: definition.edges,
      });
      storeWorkflowToStorage({
        nodes: definition.nodes,
        edges: definition.edges,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, getWorkflow: false }));
    }
  }, [workflowUuid, setLoading, setName, setNodes, setEdges, addFiltersToSessionStorage]);

  const storeWorkflowToStorage = (workflow: ReactFlowDto) => {
    window.localStorage.setItem("workflow", JSON.stringify(workflow));
  };
  const getWorkflowFromStorage = (): string => {
    return window.localStorage.getItem("workflow");
  };

  const enrichNodes = (workflowRun: WorkflowRunDto) => {
    const nodesWithData = getNodes().map(node => {
      const result = workflowRun.results.find(
        ({ node_id: workflowNodeID }) => workflowNodeID === node.id
      );
      if (result) {
        return {
          ...node,
          data: {
            ...(node.data || {}),
            samples: result.samples,
            summary: result.summary,
          },
        };
      }
      return node;
    });
    return nodesWithData;
  };

  useEffect(() => {
    document.title = "Workflow";
    setUpdateAndRunWorkflow(updateAndRunWorkflows);
  }, []);

  useEffect(() => {
    if (workflowUuid) {
      getWorkflow();
    }
  }, [workflowUuid]);

  return (
    <div style={{ width: "calc(100vw - 64px)", height: "calc(100vh - 64px)" }}>
      <div style={{ position: "relative", height: "100%", width: "100%" }} id="reactflow-container">
        <Box
          sx={{
            position: "absolute",
            top: "-50px",
            right: "40px",
            display: "flex",
            gap: 1,
          }}
        >
          {console.log("hasUNappliedChanges", hasUnappliedChanges)}
          {!disabled && hasUnappliedChanges && <FilterChipChanges label="Unapplied changes" />}
          <LoadingButton
            variant="contained"
            onClick={updateAndRunWorkflows}
            loading={disabled}
            loadingIndicator={
              <>
                <CircularProgress size="16" />
                Running
              </>
            }
          >
            Run
          </LoadingButton>
        </Box>

        <ReactFlow
          style={{ background: "#F5F5F5" }}
          proOptions={proOptions}
          nodes={nodes}
          edges={edges}
          fitView
          fitViewOptions={{
            padding: 1,
            minZoom: 0.1,
            maxZoom: 1,
            duration: 200,
          }}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodesChange={(changes: NodeChange<Node>[]) => {
            const workflow = getWorkflowFromStorage();
            onNodesChange(changes, workflow);
          }}
          onEdgesChange={onEdgesChange}
          minZoom={0.2}
          zoomOnDoubleClick={false}
          deleteKeyCode={null}
          // onNodeMouseEnter={(e, node) => setHoveredNode(node)}
          // onNodeMouseLeave={() => setHoveredNode(null)}
          edgesFocusable={!disabled}
          nodesDraggable={!disabled}
          nodesConnectable={!disabled}
          nodesFocusable={!disabled}
          draggable={!disabled}
          panOnDrag={!disabled}
          elementsSelectable={!disabled}
        >
          <Background variant={BackgroundVariant.Dots} gap={100000000000} />

          {!import.meta.env.PROD && <ReactFlowDevTools />}
        </ReactFlow>
      </div>
    </div>
  );
};

export default WorkflowsItemView;
