import { TableCell } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

interface CustomTableCellProps {
  hasDivider?: boolean;
}

type StyledTableCellProps = CustomTableCellProps & React.ComponentPropsWithoutRef<typeof TableCell>;

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: prop => prop !== "hasDivider",
})<StyledTableCellProps>(({ hasDivider }) => ({
  position: "relative",
  padding: "4px 16px",
  height: "40px",
  fontSize: "12px",
  lineHeight: "1.6",
  "&:after": {
    content: '""',
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    width: hasDivider ? "1px" : 0,
    height: "50%",
    backgroundColor: hasDivider ? "lightgrey" : "transparent",
  },
}));

export default StyledTableCell;
