import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  OutlinedInput,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useSnackbar } from "notistack";
import { MouseEventHandler, ReactElement, Ref, forwardRef, useState } from "react";
import { showSuccessNotification } from "../../common/notifications";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ICreateWorkflowModal {
  success: (data: string) => Promise<void>;
  cancel: MouseEventHandler;
}

const CreateWorkflowModal = ({ success, cancel }: ICreateWorkflowModal) => {
  const [newWorkflowName, setNewWorkflowName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <Dialog open={true} TransitionComponent={Transition} onClose={cancel}>
      <Box sx={{ position: "absolute", right: "0px", top: "0px", zIndex: "2" }}>
        <IconButton onClick={cancel} sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.25)" }}>
          <CancelIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ width: "calc(312px - 32px)", p: 2 }}>
        <DialogTitle sx={{ p: 0, mb: 1 }}>Create a new workflow</DialogTitle>
        <DialogContent sx={{ p: 0, mb: 5 }}>
          <Box sx={{ width: "280px", display: "flex", flexDirection: "column", mt: 2 }}>
            <FormControl variant="standard">
              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <Typography
                  fontSize={14}
                  fontWeight={400}
                  lineHeight={"30px"}
                  color={"rgba(0,0,0,0.6)"}
                >
                  Workflow name
                </Typography>
              </Box>
              <OutlinedInput
                id="workflow-name"
                size="small"
                sx={{
                  maxWidth: "100%",
                  background: "rgba(0, 0, 0, 0.04)",
                  borderRadius: "4px",
                  "& fieldset": {
                    outline: "none",
                    border: "none",
                  },
                }}
                placeholder={"Workflow name"}
                value={newWorkflowName}
                onChange={e => {
                  setNewWorkflowName(e.target.value);
                }}
              />
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "flex-start", p: 0 }}>
          <LoadingButton
            variant="contained"
            data-action="Create workflow [CreateWorkflowModal]"
            onClick={async () => {
              setLoading(true);
              await success(newWorkflowName);
              showSuccessNotification(`${newWorkflowName} created`, enqueueSnackbar, closeSnackbar);
              setLoading(false);
            }}
            loading={loading}
            disabled={!newWorkflowName}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreateWorkflowModal;
