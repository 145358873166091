import { NodeProps } from "@xyflow/react";
import { useShallow } from "zustand/react/shallow";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import { createEdge } from "../utils/edges";
import { createExportNode, FILTER_NODE_TYPES, NodePosition } from "../utils/nodes";

const selector = createRFSelector(
  "nodes",
  "setNodes",
  "getNode",
  "setEdges",
  "edges",
  "getIncomers"
);

export function useExportClick(id: NodeProps["id"]) {
  const { getNode, setNodes, setEdges, nodes, edges } = useStore(useShallow(selector));

  const onClick = () => {
    const currentNode = getNode(id);

    if (!currentNode) {
      return;
    }
    let position: NodePosition = {
      x: currentNode.position.x + currentNode.initialWidth + 100,
      y: currentNode.position.y,
    };
    if (FILTER_NODE_TYPES.includes(currentNode.type)) {
      const groupNode = nodes.find(node => node.id === currentNode.parentId);

      position = {
        x: groupNode.position.x + groupNode.initialWidth + 100,
        y: groupNode.position.y + currentNode.position.y,
      };
    }

    const currentIndex = nodes.findIndex(node => node.id === currentNode.id);
    const exportNode = createExportNode(position);
    const exportEdge = createEdge(currentNode.id, exportNode.id);

    const newNodes = [...nodes];
    newNodes.splice(currentIndex + 1, 0, exportNode);
    setNodes(newNodes);
    const newEdges = edges.concat([exportEdge]);
    setEdges(newEdges);
  };

  return onClick;
}

export default useExportClick;
