import ExportTargetSheets from "./ExportTargetSheets";
import ExportTargetXero from "./ExportTargetXero";
import FilterNodeRoot from "./FilterNodeRoot";
import FilterNodeSecondary from "./FilterNodeSecondary";
import FilterNodeRemaining from "./FilterNodeRemaining";
import GroupNode from "./GroupNode";
import SourceNodeMatching from "./SourceNodeMatching";

export const nodeTypes = {
  dataMatchingSource: SourceNodeMatching,
  xeroExportTarget: ExportTargetXero,
  filterNodeRoot: FilterNodeRoot,
  filterNodeSecondary: FilterNodeSecondary,
  filterNodeRemaining: FilterNodeRemaining,
  groupNode: GroupNode,
  spreadsheetNode: ExportTargetSheets,
};

export default nodeTypes;
