import React from "react";
import { Box, Drawer } from "@mui/material";
import TaskTimeline from "./TaskTimeline";
import TaskDetails from "./TaskDetails";
import { useTask } from "../hooks/useTask";

type TaskDrawerProps = {
  open: boolean;
  onClose: () => void;
};

const TaskDrawer: React.FC<TaskDrawerProps> = ({ open, onClose }) => {
  const { selectedTaskWithEvents } = useTask();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minWidth: "900px", height: "100vh" } }}
    >
      {selectedTaskWithEvents && (
        <Box height="97vh">
          <Box display="flex" height="100%">
            <Box width={580} height="100%">
              <TaskDetails task={selectedTaskWithEvents.task} />
            </Box>

            <Box
              width={320}
              borderLeft={1}
              borderColor="divider"
              sx={{ padding: 2, overflowY: "auto", height: "100%" }}
            >
              <TaskTimeline />
            </Box>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

export default TaskDrawer;
