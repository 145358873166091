import React, { useState } from "react";
import { Box, Checkbox, Collapse, List, ListItem, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TASK_TYPE } from "../constants";
import { generateMonths } from "../utils/generateMonths";
import AdjustIcon from "@mui/icons-material/Adjust";

type SidebarProps = {
  onMonthChange: (month: string) => void;
  onTaskTypeChange: (month: string, taskType: string) => void;
  selectedMonthTaskType: { month: string; taskType: string };
  taskCounts: { [month: string]: { Exports: number } };
};

const TasksTableSidebar: React.FC<SidebarProps> = ({
  onTaskTypeChange,
  selectedMonthTaskType,
  taskCounts,
}) => {
  const months = generateMonths();
  const [open, setOpen] = useState(() => {
    const initialOpen = {};
    if (months.length > 1) {
      initialOpen[months[1]] = true;
    }
    return initialOpen;
  });

  const handleMonthClick = month => {
    setOpen(prevOpen => ({
      ...prevOpen,
      [month]: !prevOpen[month],
    }));
  };

  const handleTaskTypeChange = (month, taskType) => {
    onTaskTypeChange(month, taskType);
  };

  return (
    <Box
      sx={{
        width: 250,
        flexShrink: 0,
        overflow: "auto",
        height: "100vh",
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          width: "1px",
          backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
      }}
    >
      <List>
        {months.map(month => (
          <React.Fragment key={month}>
            <ListItem
              onClick={() => handleMonthClick(month)}
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" fontSize={12}>
                {month}
              </Typography>
              {open[month] ? (
                <ExpandLess sx={{ color: "rgba(0,0,0,0.4)" }} />
              ) : (
                <ExpandMore sx={{ color: "rgba(0,0,0,0.4)" }} />
              )}
            </ListItem>
            <Collapse in={open[month]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      icon={<AdjustIcon sx={{ height: 16, color: "#D9D9D9" }} />}
                      checkedIcon={<CheckCircleIcon sx={{ height: 16, color: "#4D985E" }} />}
                      checked={
                        selectedMonthTaskType.month === month &&
                        selectedMonthTaskType.taskType === TASK_TYPE.EXPORT
                      }
                      onChange={() => handleTaskTypeChange(month, TASK_TYPE.EXPORT)}
                    />
                    <Typography fontSize={12}>Exports</Typography>
                  </Box>
                  <Typography fontSize={12} fontWeight={700} sx={{ mr: 1 }}>
                    {taskCounts[month] ? taskCounts[month].Exports : 0}
                  </Typography>
                </ListItem>
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default TasksTableSidebar;
