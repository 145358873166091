import { isDate, isFloat, isInteger, isString } from "../../../../utils/types/checkType";
import { GridColDef } from "@mui/x-data-grid";

import { Typography } from "@mui/material";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const decideType = value => {
  switch (value) {
    case isInteger(value):
      return "integer";
    case isFloat(value):
      return "integer";
    case isDate(value):
      return "date";
    case isString(value):
      return "string";
    default:
      return "string";
  }
};

export const headerNameMap = {
  rpt_project_name: "Project (Vendor)",
  et_project_name: "Project (Back-office)",
  rpt_amount: "Amount (Vendor)",
  et_amount: "Amount (Back-office)",
  rpt_status: "Status (Vendor)",
  et_status: "Status (Back-office)",
  et_type: "Transaction type",
  rpt_date: "Happening time (Vendor)",
  et_created_at: "Happening time (Back-office)",
  et_currency: "Currency (Back-office)",
  rpt_currency: "Currency (Vendor)",
  et_amount_eur: "Amount EUR (Back-office)",
  rpt_amount_eur: "Amount EUR (Vendor)",
  et_status_original: "Original status (Back-office)",
  et_brand_name: "Brand",
  et_external_id: "External ID (Back-office)",
  et_external_created_at: "Created at (Back-office)",
  et_external_updated_at: "Updated at (Back-office)",
  rpt_reference_id: "Vendor reference ID",
  rpt_linking_id: "Vendor linking ID",
  vendor_missing: "Missing vendor",
  et_uuid: "UUID (Back-office)",
  vendor_missing_upload: "Missing vendor upload",
  vendor_missing_month_end: "Missing vendor month end",
  error_date: "Date error",
  error_amount: "Amount error",
  error_status: "Status error",
  error_project: "Project error",
  other: "Other",
  matched: "Matched",
  fallback: "Fallback",
  resolved: "Resolved",
  bo_missing: "Missing back-office",
};

const CellRender = ({ value }) => {
  const hasValue = value !== null && value !== undefined;
  if (hasValue) {
    return <Typography>{value}</Typography>;
  }
  return <Typography color="rgba(0, 0, 0, 0.6)">N/A</Typography>;
};

export const createColumns = (filters): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "rpt_project_name",
      headerName: headerNameMap.rpt_project_name,
      type: "string",
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params?.row?.rpt_project_name || "N/A",
      renderCell: params => {
        const { rpt_project_name } = params?.row;
        return <CellRender value={rpt_project_name} />;
      },
    },
    {
      field: "et_project_name",
      headerName: headerNameMap.et_project_name,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.et_project_name || "N/A",
      renderCell: params => {
        const { et_project_name } = params?.row;
        return <CellRender value={et_project_name} />;
      },
    },
    {
      field: "rpt_amount",
      headerName: "Amount (Vendor)",
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.rpt_amount || "N/A",
      renderCell: params => {
        const { rpt_amount } = params?.row;
        return <CellRender value={rpt_amount} />;
      },
    },
    {
      field: "et_amount",
      headerName: "Amount (Back-office)",
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.et_amount || "N/A",
      renderCell: params => {
        const { et_amount } = params?.row;
        return <CellRender value={et_amount} />;
      },
    },
    {
      field: "rpt_status",
      headerName: "Status (Vendor)",
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.rpt_status || "N/A",
      renderCell: params => {
        const { rpt_status } = params?.row;
        return <CellRender value={rpt_status} />;
      },
    },
    {
      field: "et_status",
      headerName: headerNameMap.et_status,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.et_status || "N/A",
      renderCell: params => {
        const { et_status } = params?.row;
        return <CellRender value={et_status} />;
      },
    },
    {
      field: "et_type",
      headerName: headerNameMap.et_type,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.et_type || "N/A",
      renderCell: params => {
        const { et_type } = params?.row;
        return <CellRender value={et_type} />;
      },
    },
    {
      field: "rpt_date",
      headerName: headerNameMap.rpt_date,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.rpt_date || "N/A",
      renderCell: params => {
        const { rpt_date } = params?.row;
        const dateValue = rpt_date ? dayjs.utc(rpt_date).format("YYYY-MM-DD [at] HH:mm:ss") : null;
        return <CellRender value={dateValue} />;
      },
    },
    {
      field: "et_created_at",
      headerName: headerNameMap.et_created_at,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.et_created_at || "N/A",
      renderCell: params => {
        const { et_created_at } = params?.row;
        const dateValue = et_created_at
          ? dayjs.utc(et_created_at).format("YYYY-MM-DD [at] HH:mm:ss")
          : null;
        return <CellRender value={dateValue} />;
      },
    },

    {
      field: "et_currency",
      headerName: headerNameMap.et_currency,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.et_currency || "N/A",
      renderCell: params => {
        const { et_currency } = params?.row;
        return <CellRender value={et_currency} />;
      },
    },
    {
      field: "rpt_currency",
      headerName: headerNameMap.rpt_currency,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.rpt_currency || "N/A",
      renderCell: params => {
        const { rpt_currency } = params?.row;
        return <CellRender value={rpt_currency} />;
      },
    },

    {
      field: "et_amount_eur",
      headerName: headerNameMap.et_amount_eur,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.et_amount_eur || "N/A",
      renderCell: params => {
        const { et_amount_eur } = params?.row;
        return <CellRender value={et_amount_eur} />;
      },
    },
    {
      field: "rpt_amount_eur",
      headerName: headerNameMap.rpt_amount_eur,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.rpt_amount_eur || "N/A",
      renderCell: params => {
        const { rpt_amount_eur } = params?.row;
        return <CellRender value={rpt_amount_eur} />;
      },
    },
    {
      field: "et_status_original",
      headerName: headerNameMap.et_status_original,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.et_status_original || "N/A",
      renderCell: params => {
        const { et_status_original } = params?.row;
        return <CellRender value={et_status_original} />;
      },
    },

    {
      field: "et_brand_name",
      headerName: headerNameMap.et_brand_name,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.et_brand_name || "N/A",
      renderCell: params => {
        const { et_brand_name } = params?.row;
        return <CellRender value={et_brand_name} />;
      },
    },

    {
      field: "et_external_id",
      headerName: headerNameMap.et_external_id,
      width: 180,
      resizable: true,

      sortable: false,

      valueGetter: params => params.row.et_external_id || "N/A",
      renderCell: params => {
        const { et_external_id } = params?.row;
        return <CellRender value={et_external_id} />;
      },
    },
    {
      field: "et_external_created_at",
      headerName: headerNameMap.et_external_created_at,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.et_external_created_at || "N/A",
      renderCell: params => {
        const { et_external_created_at } = params?.row;
        return <CellRender value={et_external_created_at} />;
      },
    },
    {
      field: "et_external_updated_at",
      headerName: headerNameMap.et_external_updated_at,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.et_external_updated_at || "N/A",
      renderCell: params => {
        const { et_external_updated_at } = params?.row;
        return <CellRender value={et_external_updated_at} />;
      },
    },

    {
      field: "rpt_reference_id",
      headerName: headerNameMap.rpt_reference_id,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.rpt_reference_id || "N/A",
      renderCell: params => {
        const { rpt_reference_id } = params?.row;
        return <CellRender value={rpt_reference_id} />;
      },
    },

    {
      field: "rpt_linking_id",
      headerName: headerNameMap.rpt_linking_id,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.rpt_linking_id || "N/A",
      renderCell: params => {
        const { rpt_linking_id } = params?.row;
        return <CellRender value={rpt_linking_id} />;
      },
    },
    {
      field: "vendor_missing",
      headerName: headerNameMap.vendor_missing,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.vendor_missing || "N/A",
      renderCell: params => {
        const { vendor_missing } = params?.row;
        return <CellRender value={vendor_missing} />;
      },
    },
    {
      field: "vendor_missing_upload",
      headerName: headerNameMap.vendor_missing_upload,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.vendor_missing_upload || "N/A",
      renderCell: params => {
        const { vendor_missing_upload } = params?.row;
        return <CellRender value={vendor_missing_upload} />;
      },
    },
    {
      field: "vendor_missing_month_end",
      headerName: headerNameMap.vendor_missing_month_end,
      width: 180,
      resizable: true,
      sortable: false,

      valueGetter: params => params.row.vendor_missing_month_end || "N/A",
      renderCell: params => {
        const { vendor_missing_month_end } = params?.row;
        return <CellRender value={vendor_missing_month_end} />;
      },
    },
    {
      field: "error_date",
      headerName: headerNameMap.error_date,
      width: 180,
      resizable: true,
      sortable: false,
    },
    {
      field: "error_amount",
      headerName: headerNameMap.error_amount,
      width: 180,
      resizable: true,
      sortable: false,
    },
    {
      field: "error_status",
      headerName: headerNameMap.error_status,
      width: 180,
      resizable: true,
      sortable: false,
    },
    {
      field: "error_project",
      headerName: headerNameMap.error_project,
      width: 180,
      resizable: true,
      sortable: false,
    },
    {
      field: "other",
      headerName: headerNameMap.other,
      width: 180,
      resizable: true,
      sortable: false,
    },

    {
      field: "matched",
      headerName: headerNameMap.matched,
      width: 180,
      resizable: true,
      sortable: false,
    },
    {
      field: "fallback",
      headerName: headerNameMap.fallback,
      width: 180,
      resizable: true,
      sortable: false,
    },
    {
      field: "resolved",
      headerName: "Resolved",
      width: 180,
      resizable: true,
      sortable: false,
    },
    {
      field: "bo_missing",
      headerName: "Missing back-office",
      width: 180,
      resizable: true,
      sortable: false,
    },
  ].sort((a, b) => {
    const aInFilters = filters?.items?.some(item => item.field === a.field);
    const bInFilters = filters?.items?.some(item => item.field === b.field);

    if (aInFilters && bInFilters) {
      return 0;
    }
    if (aInFilters) {
      return -1;
    }
    if (bInFilters) {
      return 1;
    }
    return 0;
  });

  return columns;
};

export const createRows = data => {};
