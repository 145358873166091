import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, MouseEventHandler, ReactElement, Ref, useState } from "react";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IDeleteWorkflowModal {
  success: () => Promise<void>;
  cancel: MouseEventHandler;
}

const DeleteWorkflowDialog = (props: IDeleteWorkflowModal) => {
  const { success, cancel } = props;
  const [inputValue, setInputValue] = useState<string>("");
  const isCorrectInput: boolean = inputValue.toLowerCase() === "delete";
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Dialog open={true} TransitionComponent={Transition} onClose={cancel}>
      <Box sx={{ position: "absolute", right: "0px", top: "0px", zIndex: "2" }}>
        <IconButton onClick={cancel} sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.25)" }}>
          <CancelIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ width: "calc(312px - 32px)", p: 2 }}>
        <DialogTitle sx={{ p: 0, mb: 1 }}>Delete workflow</DialogTitle>
        <DialogContent sx={{ p: 0, mb: 5 }}>
          <Box sx={{ width: "280px", display: "flex", flexDirection: "column" }}>
            <TextField
              label={""}
              placeholder={"Type DELETE to confirm"}
              value={inputValue}
              autoFocus
              onChange={e => {
                setInputValue(e.target.value);
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "flex-start", p: 0 }}>
          <LoadingButton
            variant="contained"
            data-action="Delete [DeleteWorkflowDialog]"
            disabled={!isCorrectInput}
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await success();
              setLoading(false);
            }}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DeleteWorkflowDialog;
