import React, { useEffect, useState } from "react";
import { Box, ClickAwayListener, Typography } from "@mui/material";
import dayjs from "dayjs";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Popper from "@mui/material/Popper";
import utc from "dayjs/plugin/utc";
import { TaskDto } from "../../../../spec/bo";
import { useTask } from "../hooks/useTask";

dayjs.extend(utc);

interface EditableDateInputProps {
  task?: TaskDto;
}

const EditableDateInput: React.FC<EditableDateInputProps> = ({ task: propTask }) => {
  const { selectedTaskWithEvents, updateTask } = useTask();
  const [editMode, setEditMode] = useState(false);
  const [dueDate, setDueDate] = useState<dayjs.Dayjs | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const task = propTask || selectedTaskWithEvents?.task;

  useEffect(() => {
    if (task?.due_date) {
      setDueDate(dayjs(task.due_date));
    } else {
      setDueDate(null);
    }
  }, [task]);

  const handleEdit = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setEditMode(true);
  };

  const handleDateChange = async (newValue: dayjs.Dayjs | null) => {
    if (!task) return;

    setDueDate(newValue);
    setEditMode(false);
    try {
      await updateTask(task.uuid, {
        due_date: newValue ? newValue.utc().toISOString() : null,
      });
    } catch (error) {
      console.error("Error updating due date:", error);
    }
  };

  const handleClickAway = () => {
    setEditMode(false);
  };

  if (!task) return null;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Typography
          variant="body2"
          fontSize={12}
          color="rgba(0,0,0,0.6)"
          onClick={handleEdit}
          sx={{ cursor: "pointer" }}
        >
          {dueDate ? dayjs(dueDate).utc().format("DD.MM.YYYY") : "Set due date..."}
        </Typography>
        <Popper
          open={editMode}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{ zIndex: 1300 }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={dueDate}
              onChange={handleDateChange}
              sx={{
                backgroundColor: "white",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "4px",
                "& .MuiPickersDay-root": {
                  fontSize: "0.875rem",
                },
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
              }}
            />
          </LocalizationProvider>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default EditableDateInput;
