import { Box, IconButton, Select, SelectProps, Typography } from "@mui/material";
import { truncateString } from "../../../core/utils";
import { Cancel } from "@mui/icons-material";
import Checkbox from "../Checkbox/Checkbox";
import MenuItem from "./MenuItem";

/**
 * InputSelectMulti Interface
 *
 * @interface IInputSelectMulti
 * @property {number} truncateLength truncateLength The length to truncate the string to
 * @property {boolean} disableRemoveButton Whether to disable the remove button
 * @property {string[]} headers The headers to display in the Select
 * @property {Record<string, any>[]} allValues The values that are going to be displayed in the Select
 * @property {string} emptyText The text to display when the field is empty
 * @property {function} clearFunction Function to clear the field of its values
 * @property {string} valueKey The key to use to get the value from the allValues array to display in the Select
 * @property {SelectProps} rest The rest of the MuiSelect props
 *
 */
export interface IInputSelectMulti extends SelectProps {
  truncateLength?: number;
  disableRemoveButton?: boolean;
  headers?: string[];
  allValues?: Record<string, any>[];
  emptyText?: string;
  clearFunction?: () => void;
  onChangeArgument: string;
  valueKey: string;
  value: string[];
}

const InputSelectMulti: React.FC<IInputSelectMulti> = (props: IInputSelectMulti): JSX.Element => {
  const {
    allValues,
    emptyText,
    truncateLength = 10,
    valueKey = "name",
    disableRemoveButton = false,
    onChangeArgument,
    ...rest
  } = props;

  const { value } = props;

  const hasValue = Array.isArray(props.value) ? props.value.length > 0 : props.value !== "";
  const hasMultipleValue = Array.isArray(props.value) && props.value.length > 1;
  const valueCount = Array.isArray(props.value) && props.value.length;

  const isValueChecked = (item: string) => {
    if (Array.isArray(props.value)) return props.value.indexOf(item) > -1;
    if (props.value === item) return true;

    return false;
  };

  const CountValueElement = ({ count }) => {
    if (count === 0) return <Box>{count}</Box>;
    if (count > 0)
      return (
        <Box
          sx={{
            backgroundColor: "#142E90",
            minWidth: "14px",
            minHeight: "16px",
            position: "relative",
            borderRadius: "4px",
            mr: 0.75,
          }}
        >
          <Typography
            fontSize="12px"
            fontWeight="700"
            fontFamily="ABCWhyte"
            color="#FFF"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              WebkitTextFillColor: "#FFF",
            }}
          >
            {count}
          </Typography>
        </Box>
      );
  };

  return (
    <Select
      id={`${props.label}-select`}
      multiple
      autoFocus={false}
      disableUnderline
      displayEmpty
      size="small"
      sx={{
        backgroundColor: hasValue ? "#EEF1FB" : "rgba(0, 0, 0, 0.04)",
        border: "1px solid transparent",
        borderColor: hasValue ? "#BCC8F5" : "transparent",
        borderRadius: "4px",

        "& .MuiSelect-icon": {
          fill: "#000",
        },
        "& fieldset": {
          outline: "none",
          border: "none",
        },
      }}
      {...rest}
      renderValue={item => {
        const selectedValue = Array.isArray(item) ? item[0] : item;
        if (hasMultipleValue)
          return (
            <Box sx={{ display: "flex", alignContent: "center", alignItems: "center" }}>
              <CountValueElement count={valueCount} />
              <Typography color="#122877">{props.label}</Typography>
              {!disableRemoveButton && (
                <IconButton size="small" className="clearField" sx={{ padding: 0.25 }}>
                  <Cancel className="clearField" sx={{ fontSize: "16px" }} />
                </IconButton>
              )}
            </Box>
          );
        if (hasValue && !hasMultipleValue) {
          const valueItem = allValues?.find(item => item.uuid === selectedValue);
          let name = valueItem?.[valueKey];
          if (!name) name = props.label;
          return (
            <Box sx={{ display: "flex", alignContent: "center", alignItems: "center" }}>
              <CountValueElement count={valueCount} />
              <Typography color="#122877">
                {name.charAt(0).toUpperCase() + truncateString(name, truncateLength).slice(1)}
              </Typography>
              {!disableRemoveButton && (
                <IconButton size="small" className="clearField" sx={{ padding: 0.25 }}>
                  <Cancel className="clearField" sx={{ fontSize: "16px" }} />
                </IconButton>
              )}
            </Box>
          );
        }
        return <Typography>{emptyText ? emptyText : props.label}</Typography>;
      }}
    >
      <MenuItem value="all" selected={value.length === allValues.length}>
        <Checkbox checked={value.length === allValues.length} />
        Select all
      </MenuItem>
      {allValues?.map((item, index) => (
        <MenuItem key={index} value={item.uuid}>
          <Checkbox checked={isValueChecked(item.uuid)} />
          {item[valueKey]}
        </MenuItem>
      ))}
    </Select>
  );
};

export default InputSelectMulti;
