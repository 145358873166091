import { Box, Chip } from "@mui/material";
import styled from "styled-components";

export const DataSourceNodeTable = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: rgba(0, 0, 0, 0.04);
  padding: 4px 16px;
  border-radius: 4px;
  margin-top: 8px;

}
`;

export const DataSourceNodeTableHeader = styled(Box)`
  display: grid;
  gap: 8px;
  grid-template-columns: minmax(150px, auto) minmax(100px, auto) minmax(126px, auto);
  color: rgba(0, 0, 0, 0.6);
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  & > :not(:first-child) {
    text-align: right;
  }
`;

export const FilterTableRow = styled(DataSourceNodeTableHeader)`
  grid-template-columns: minmax(76px, auto) minmax(100px, auto) minmax(100px, auto);
  &:last-child {
    border-bottom: none;
  }
`;
export const ExportTableRow = styled(DataSourceNodeTableHeader)`
  grid-template-columns: minmax(150px, auto) minmax(100px, auto) minmax(126px, auto);
  &:last-child {
    border-bottom: none;
  }
`;

export const DataSourceNodeTableRow = styled(DataSourceNodeTableHeader)`
  &:last-child {
    border-bottom: none;
  }
  & > :nth-child(3) {
    min-width: 86px;
  }
`;

export const DataSourceNodeTableRowTwo = styled(DataSourceNodeTableRow)`
  grid-template-columns: 1fr 1fr;
`;

export const EdgePath = styled.path`
  cursor: pointer;
  pointer-events: all;
  stroke: #0984e3;
  fill: white;
  &:hover {
    fill: #f5f5f5;
  }
`;

export const PlaceholderEdge = styled.path`
  .placeholderPath {
    stroke-width: 1;
    stroke-dasharray: 3 3;
    stroke: #bbb;
    fill: none;
  }
`;

export const FilterChip = styled(Chip)`
  border-radius: 4px;
  font-size: 12px;
  padding: 4px 8px;
  background-color: #fff;
  color: ${({ subtitle }) => (subtitle ? "rgba(0, 0, 0, 0.12)" : "#000")};
  border: 1px solid rgba(0, 0, 0, 0.12);
  & > .MuiChip-label {
    padding: 0;
  }
`;

export const FilterChipChanges = styled(Chip)`
  border-radius: 4px;
  font-size: 12px;
  color: #869533;
  padding: 4px 6px;
  background-color: #f9ffd6;
  border: 1px solid #b9c868;
  & > .MuiChip-label {
    padding: 0;
  }
`;
