import DateRangeIcon from "@mui/icons-material/DateRange";
import { Box, Button, ClickAwayListener, Paper, Typography } from "@mui/material";
import {
  endOfDay,
  endOfMonth,
  format,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  subMonths,
} from "date-fns";
import { enGB } from "date-fns/locale";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DateRangePicker } from "react-date-range";

import { useState } from "react";
import "./InputDateRangePicker.scss";

interface InputDateRangePickerProps {
  dateRange: {
    startDate: string;
    endDate: string;
  };
  changeHook: (startDate: Date, endDate: Date) => void;
}
dayjs.extend(utc);
dayjs.utc().format();
const InputDateRangePicker = (props: InputDateRangePickerProps) => {
  const { dateRange } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [currentRanges, setCurrentRanges] = useState([
    {
      startDate: new Date(
        dayjs(dateRange.startDate).utc(false).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      ),

      endDate: new Date(
        dayjs(dateRange.endDate).utc(false).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      ),
      key: "selection",
    },
  ]);

  const [{ startDate, endDate }] = currentRanges;

  const staticRangeHandler = {
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };

  const createStaticRanges = ranges => ranges.map(range => ({ ...staticRangeHandler, ...range }));

  const createUTCDate = (): string => dayjs(new Date()).utc(false).toISOString();

  const createStart = (number: number): Date =>
    startOfMonth(subMonths(startOfDay(createUTCDate()), number));

  const createEnd = (number: number): Date =>
    endOfMonth(subMonths(endOfDay(createUTCDate()), number));

  const defineds = {
    startOfMonth: createStart(0),
    endOfMonth: createEnd(0),
    startOfLastMonth: createStart(1),
    endOfLastMonth: createEnd(1),
    startOfMinus2Months: createStart(2),
    endOfMinus2Months: createEnd(2),
    startOfMinus3Months: createStart(3),
    endOfMinus3Months: createEnd(3),
    startOfMinus4Months: createStart(4),
    endOfMinus4Months: createEnd(4),
    startOfMinus5Months: createStart(5),
    endOfMinus5Months: createEnd(5),
    startOfMinus6Months: createStart(6),
    endOfMinus6Months: createEnd(6),
    startOfCurrentYear: startOfDay(startOfYear(createUTCDate())),
    endOfCurrentYear: endOfDay(createUTCDate()),
  };

  const defaultStaticRanges = createStaticRanges([
    {
      label: `This month`,
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    },
    {
      label: `Last month`,
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
    },
    {
      label: `Ongoing year`,
      range: () => ({
        startDate: defineds.startOfCurrentYear,
        endDate: defineds.endOfCurrentYear,
      }),
    },
    {
      label: `${format(defineds.startOfMinus2Months, "LLLL yyyy")}`,
      range: () => ({
        startDate: defineds.startOfMinus2Months,
        endDate: defineds.endOfMinus2Months,
      }),
    },
    {
      label: `${format(defineds.startOfMinus3Months, "LLLL yyyy")}`,
      range: () => ({
        startDate: defineds.startOfMinus3Months,
        endDate: defineds.endOfMinus3Months,
      }),
    },
    {
      label: `${format(defineds.startOfMinus4Months, "LLLL yyyy")}`,
      range: () => ({
        startDate: defineds.startOfMinus4Months,
        endDate: defineds.endOfMinus4Months,
      }),
    },
    {
      label: `${format(defineds.startOfMinus5Months, "LLLL yyyy")}`,
      range: () => ({
        startDate: defineds.startOfMinus5Months,
        endDate: defineds.endOfMinus5Months,
      }),
    },
    {
      label: `${format(defineds.startOfMinus6Months, "LLLL yyyy")}`,
      range: () => ({
        startDate: defineds.startOfMinus6Months,
        endDate: defineds.endOfMinus6Months,
      }),
    },
  ]);

  const closeAndReset = () => {
    setCurrentRanges([
      {
        startDate: new Date(
          dayjs(dateRange.startDate).utc(false).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        ),
        endDate: new Date(
          dayjs(dateRange.endDate).utc(false).endOf("month").format("YYYY-MM-DD HH:mm:ss")
        ),
        key: "selection",
      },
    ]);

    setOpen(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        onClick={() => setOpen(!open)}
        sx={{
          padding: "8.5px 14px",
          width: "170px",
          zIndex: 9,
          backgroundColor: "#EEF1FB",
          border: "1px solid transparent",
          borderColor: "#BCC8F5",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <DateRangeIcon className="icon" sx={{ fill: "#142E90", mr: 0.75 }} fontSize="small" />
        <Typography color="#122877">{`${dayjs(startDate).format("DD MMM")} - ${dayjs(
          endDate
        ).format("DD MMM YYYY")}`}</Typography>
      </Box>
      {open && (
        <ClickAwayListener onClickAway={() => closeAndReset()}>
          <Box
            component={Paper}
            onKeyDown={e => {
              if (e.key === "Escape") closeAndReset();
              if (e.key === "Enter") {
                props.changeHook(startDate, endDate);
                setOpen(false);
              }
            }}
            sx={{
              position: "absolute",
              backgroundColor: "white",
              zIndex: 99999999999,
              border: "1px solid rgba(0, 0, 0, 0.04)",
              borderRadius: "4px",
              top: "50px",
            }}
          >
            <DateRangePicker
              showSelectionPreview={true}
              editableDateInputs={true}
              dateDisplayFormat="dd.MM.yyyy"
              startDatePlaceholder={"dd.MM.yyyy"}
              endDatePlaceholder={"dd.MM.yyyy"}
              moveRangeOnFirstSelection={false}
              fixedHeight={true}
              months={2}
              locale={enGB}
              ranges={currentRanges}
              maxDate={new Date()}
              staticRanges={defaultStaticRanges}
              direction="horizontal"
              onChange={item => setCurrentRanges([item.selection])}
              {...props}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                p: 2,
                borderTop: "1px solid  rgba(0, 0, 0, 0.12)",
              }}
            >
              <Button variant="outlined" onClick={() => closeAndReset()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                data-action="Apply dates [InputDateRangePicker]"
                onClick={() => {
                  props.changeHook(startDate, endDate);
                  setOpen(false);
                }}
              >
                Apply dates
              </Button>
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default InputDateRangePicker;
