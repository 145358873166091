import { Box, Typography } from "@mui/material";
import { Position } from "@xyflow/react";
import {
  DataSourceNodeTable,
  DataSourceNodeTableHeader,
  DataSourceNodeTableRow,
} from "../components/ReactflowElements";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import InputDateRangePicker from "../../../../components/UI/Input/InputDateRangePicker";
import { formatNumber, formatNumberWithoutCommas } from "../../../../core/utils";
import SingleConnectionHandle from "../handleTypes/Handle";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import { useShallow } from "zustand/react/shallow";
import SingleConnectionIconHandle from "../handleTypes/AddPlaceholderHandle";
dayjs.extend(utc);

const selector = createRFSelector("nodes", "setNodes", "updateAndRunWorkflow");

const SourceNodeMatching = ({ data, isConnectable, id }) => {
  const { nodes, setNodes, updateAndRunWorkflow } = useStore(useShallow(selector));
  const { date_range } = data;

  const dateRange = {
    startDate: dayjs(date_range.start_date).toISOString(),
    endDate: dayjs(date_range.end_date).toISOString(),
  };

  const onChange = (startDate: Date, endDate: Date) => {
    const newNodes = nodes.map(item => {
      if (item.id === id) {
        return {
          ...item,
          data: {
            ...item.data,
            date_range: {
              start_date: dayjs(startDate).utc(true).toISOString(),
              end_date: dayjs(endDate).utc(true).toISOString(),
            },
          },
        };
      }
      return item;
    });
    setNodes(newNodes);
    handleRun();
  };

  const handleRun = () => {
    if (updateAndRunWorkflow) {
      updateAndRunWorkflow();
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.12)",
          p: 2,
          backgroundColor: "white",
          borderRadius: "4px",
          width: "432px",
          zIndex: 9,
        }}
      >
        <Typography variant="h5" fontSize="12px" fontWeight="700">
          Transactions in time range
        </Typography>
        <Box sx={{ zIndex: 9, my: 1 }}>
          <InputDateRangePicker
            dateRange={dateRange}
            changeHook={(startDate, endDate) => onChange(startDate, endDate)}
          />
        </Box>
        <DataSourceNodeTable>
          <DataSourceNodeTableHeader>
            <DataSourceNodeTableRow>
              <Typography variant="body1" fontSize="12px">
                Description
              </Typography>
              <Typography variant="body1" fontSize="12px">
                Count
              </Typography>
              <Typography variant="body1" fontSize="12px">
                Amount EUR
              </Typography>
            </DataSourceNodeTableRow>
          </DataSourceNodeTableHeader>

          {data?.summary?.rows &&
            data?.summary?.rows.map((item, index) => {
              const fontWeight = index === 0 ? "700" : "400";
              return (
                <DataSourceNodeTableRow key={index}>
                  <Typography variant="body1" fontSize="12px" fontWeight={fontWeight}>
                    {item.description}
                  </Typography>
                  <Typography variant="body1" fontSize="12px" fontWeight={fontWeight}>
                    {formatNumberWithoutCommas(item.count)}
                  </Typography>
                  <Typography variant="body1" fontSize="12px" fontWeight={fontWeight}>
                    {formatNumber(item.amount_eur)}
                  </Typography>
                </DataSourceNodeTableRow>
              );
            })}
          {!data?.summary?.rows && (
            <DataSourceNodeTableRow>
              <Typography variant="body1" fontSize="12px" fontWeight="700">
                No transactions
              </Typography>
              <Typography variant="body1" fontSize="12px" fontWeight="700">
                0
              </Typography>
              <Typography variant="body1" fontSize="12px" fontWeight="700">
                0.00
              </Typography>
            </DataSourceNodeTableRow>
          )}
        </DataSourceNodeTable>
      </Box>
      <SingleConnectionIconHandle
        type="source"
        parentId={id}
        position={Position.Right}
        id="continuationSource"
        isConnectable={isConnectable}
      />
    </>
  );
};

export default SourceNodeMatching;
