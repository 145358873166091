import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import StyledSwitch from "../../../../components/Styled/StyledSwitch";
import StyledAvatar from "../../../../components/Styled/StyledAvatar";
import dayjs from "dayjs";
import { TaskDto } from "../../../../spec/bo";
import { createAvatarContent, UserInfo } from "../utils/taskCellUtils";
import ApprovalModal from "./ApprovalModal";

interface TaskDetailsReviewerRowProps {
  task: TaskDto;
}

const TaskDetailsReviewerRow: React.FC<TaskDetailsReviewerRowProps> = ({ task }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isApproved = !!task.reviewed_at;
  const displayName = task.can_review ? "You" : "User";

  const handleSwitchChange = () => {
    if (task.can_review) {
      setIsModalOpen(true);
    }
  };

  const handleModalApprove = async () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Grid container spacing={3} sx={{ height: 40, pb: 6 }} alignItems="center">
        <Grid item xs={2}>
          <Typography fontSize={12} variant="body2" color="rgba(0, 0, 0, 0.6)">
            Reviewer
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid container direction="row" alignItems="center">
            <StyledAvatar>
              {createAvatarContent(
                false,
                task.can_review ? "You" : task.reviewer?.name,
                task.can_review,
                null
              )}
            </StyledAvatar>
            <UserInfo name={displayName} />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
            <StyledSwitch
              checked={isApproved}
              onChange={handleSwitchChange}
              disabled={!task.can_review}
              sx={{
                "& .MuiSwitch-switchBase.Mui-disabled": {
                  opacity: 0.5,
                },
                "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
                  opacity: 0.3,
                },
              }}
            />
            <Box ml={2}>
              {isApproved ? (
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Typography variant="caption" color="textSecondary">
                    Signed
                  </Typography>
                  <Typography
                    variant="body2"
                    color="rgba(0, 0, 0, 0.35)"
                    lineHeight={1.4}
                    fontSize={10}
                  >
                    {dayjs.utc(task.reviewed_at).local().format("DD.MM.YYYY HH:mm")}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="caption" color="textSecondary">
                  Waiting for signature
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ApprovalModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onApprove={handleModalApprove}
        task={task}
      />
    </>
  );
};

export default TaskDetailsReviewerRow;
