/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProjectDto } from '../models/CreateProjectDto';
import type { LatestSyncDto } from '../models/LatestSyncDto';
import type { ModelDto } from '../models/ModelDto';
import type { ModelSearchOptionsDto } from '../models/ModelSearchOptionsDto';
import type { ProjectDto } from '../models/ProjectDto';
import type { ProjectTransactionCountDto } from '../models/ProjectTransactionCountDto';
import type { UpdateProjectDto } from '../models/UpdateProjectDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProjectsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns LatestSyncDto Success
   * @throws ApiError
   */
  public getLatestSync(): CancelablePromise<LatestSyncDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/projects/sync/latest',
    });
  }

  /**
   * @returns ProjectDto Success
   * @throws ApiError
   */
  public getProjects(): CancelablePromise<Array<ProjectDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/projects',
    });
  }

  /**
   * @param requestBody
   * @returns ProjectDto Success
   * @throws ApiError
   */
  public createProject(
    requestBody?: CreateProjectDto,
  ): CancelablePromise<ProjectDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/projects',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param projectUuid
   * @returns any Success
   * @throws ApiError
   */
  public deleteProject(
    projectUuid: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/projects/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
    });
  }

  /**
   * @param projectUuid
   * @param requestBody
   * @returns ProjectDto Success
   * @throws ApiError
   */
  public updateProject(
    projectUuid: string,
    requestBody?: UpdateProjectDto,
  ): CancelablePromise<ProjectDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/projects/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param projectUuid
   * @returns ProjectDto Success
   * @throws ApiError
   */
  public getProjectByGuid(
    projectUuid: string,
  ): CancelablePromise<ProjectDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/projects/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
    });
  }

  /**
   * @param periods
   * @returns ProjectTransactionCountDto Success
   * @throws ApiError
   */
  public getProjectTransactionCountForPeriod(
    periods?: string,
  ): CancelablePromise<Array<ProjectTransactionCountDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/projects/counts',
      query: {
        'periods': periods,
      },
    });
  }

  /**
   * @param requestBody
   * @returns ModelDto Success
   * @throws ApiError
   */
  public searchModels(
    requestBody?: ModelSearchOptionsDto,
  ): CancelablePromise<Array<ModelDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/projects/search/models',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
