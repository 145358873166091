import React, { useEffect, useRef, useState } from "react";
import { Box, InputBase, styled, Typography } from "@mui/material";
import { useTask } from "../hooks/useTask";

const StyledInput = styled(InputBase)(() => ({
  position: "absolute",
  top: 1,
  left: 0,
  bottom: 0,
  fontSize: 12,
  lineHeight: 1.6,
  mb: 2,
  color: "rgba(0,0,0,0.6)",
  "& input": {
    color: "inherit",
    "&:focus": {
      outline: "none",
    },
  },
}));

const DEFAULT_DESCRIPTION = "Type a description";

const EditableDescription = () => {
  const { selectedTaskWithEvents, updateTask } = useTask();
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(selectedTaskWithEvents?.task.description || "");
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    setDescription(selectedTaskWithEvents?.task.description || "");
  }, [selectedTaskWithEvents]);

  const handleFocus = () => {
    setIsEditing(true);
    if (!description) {
      setDescription("");
    }
  };

  const handleBlur = async () => {
    setIsEditing(false);
    if (description !== selectedTaskWithEvents?.task.description) {
      try {
        await updateTask(selectedTaskWithEvents!.task.uuid, {
          description: description || null,
        });
      } catch (error) {
        console.error("Failed to update description:", error);
        setDescription(selectedTaskWithEvents?.task.description || "");
      }
    }
  };

  const handleChange = event => setDescription(event.target.value);

  const displayText = description || (isEditing ? "" : DEFAULT_DESCRIPTION);

  if (!selectedTaskWithEvents) return null;

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "12px",
      }}
    >
      {isEditing ? (
        <StyledInput
          fullWidth
          value={description}
          onChange={handleChange}
          onBlur={handleBlur}
          inputRef={inputRef}
          multiline
          placeholder={DEFAULT_DESCRIPTION}
        />
      ) : (
        <Typography
          fontSize={12}
          lineHeight={1.6}
          variant="body2"
          color="rgba(0,0,0,0.6)"
          onClick={handleFocus}
        >
          {displayText}
        </Typography>
      )}
    </Box>
  );
};

export default EditableDescription;
