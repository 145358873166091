import EditLineIcon from "@mui/icons-material/BorderColor";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { Position } from "@xyflow/react";
import { Fragment, useState } from "react";
import { formatNumberWithCommas, formatNumberWithoutCommas } from "../../../../core/utils";
import useConfirm from "../../../../hooks/useConfirm";
import FilterPortal from "../components/FilterPortal";
import {
  DataSourceNodeTable,
  FilterChip,
  FilterChipChanges,
  FilterTableRow,
} from "../components/ReactflowElements";
import SingleConnectionHandle from "../handleTypes/Handle";
import useDeleteFilterClick from "../hooks/useDeleteFilterClick";
import { useShallow } from "zustand/react/shallow";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import { headerNameMap } from "../utils/table";
import { ReactComponent as FilterDownIcon } from "../../../../assets/icons/filterDown.svg";
import AddPlaceholderHandle from "../handleTypes/AddPlaceholderHandle";
import AddFilterHandle from "../handleTypes/AddFilterHandle";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RunButton from "../../../../components/UI/Button/RunButton";

interface IFilterNodeCoreProps {
  data: any;
  isConnectable: boolean;
  id: string;
  isSecondary: boolean;
}

const selector = createRFSelector(
  "hoveredNode",
  "hasNodeChanged",
  "updateAndRunWorkflow",
  "disabled"
);

const FilterNodeCore = (props: IFilterNodeCoreProps) => {
  const { data, isConnectable, id, isSecondary } = props;
  const [open, setOpen] = useState<boolean>(false);
  const hasData: boolean = data && data?.samples;
  const maximumFilterChipWordLength = 40;

  const { hasNodeChanged, hoveredNode, updateAndRunWorkflow, disabled } = useStore(
    useShallow(selector)
  );
  const x = hasNodeChanged(id);
  const isHovered = hoveredNode && hoveredNode.id === id;
  const [ConfirmDialog, confirmDelete] = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this filter?"
  );

  const onDelete = useDeleteFilterClick(id);

  const processFilterChips = (
    filterChips: { id: string; field: string; value: string; operator: string }[],
    maximumFilterChipWordLength: number
  ) => {
    let totalLength = 0;
    const processedChips: JSX.Element[] = [];
    if (!filterChips) return null;

    for (let i = 0; i < filterChips.length; i++) {
      const chip = filterChips[i];
      const normalizeField = headerNameMap[chip.field];
      const chipCopy = `${normalizeField} ${chip.operator} ${chip.value ? chip.value : ""}`;
      const chipLength = chipCopy.length;
      console.log(totalLength + chipLength);
      if (totalLength + chipLength > maximumFilterChipWordLength) {
        const truncatedChip =
          chipCopy.substring(0, maximumFilterChipWordLength - totalLength) + "...";
        processedChips.push(<FilterChip key={chip.id} label={truncatedChip} />);
        break;
      } else {
        processedChips.push(<FilterChip key={chip.id} label={chipCopy} />);
        totalLength += chipLength;
      }

      if (processedChips.length >= 2) {
        break;
      }
    }

    return processedChips;
  };
  const filterChips = data?.filters?.items;
  const processedChips = processFilterChips(filterChips, maximumFilterChipWordLength);

  const handleDelete = async () => {
    const confirm = await confirmDelete();
    if (confirm) onDelete();
  };

  const consolidatedValues = data?.summary?.headers.map((header: any) => {
    const value = data.summary.rows[0][header.key];
    return {
      key: header.value,
      description: header.value,
      value: value,
    };
  });

  return (
    <div>
      <FilterPortal openHook={{ open, setOpen }} id={id} data={data} />
      <ConfirmDialog />
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.12)",
          p: "14px 16px",
          width: "352px",
          backgroundColor: "white",
          borderRadius: "4px",
          position: "relative",
        }}
      >
        {isSecondary && (
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,

              cursor: "pointer",
            }}
            size="small"
            onClick={handleDelete}
          >
            <CancelIcon fontSize="small" sx={{ color: "#BFBFBF" }} />
          </IconButton>
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography variant="h5" fontSize="12px" fontWeight="700">
              {data?.label}
              {/* {id} */}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
            {x && !disabled && (
              <>
                <RunButton onClick={updateAndRunWorkflow} />
                <FilterChipChanges label="Unapplied changes" />
              </>
            )}
            <Button
              variant="text"
              size="small"
              disabled={!hasData}
              onClick={() => setOpen(true)}
              startIcon={<EditLineIcon />}
            >
              Edit
            </Button>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 0.5, my: 1 }}>{processedChips}</Box>

        {/* <Box sx={{ display: "flex", gap: 0.5, my: 1 }}>
          {data?.filters?.items?.map(({ value, field, operator }, index) => {
            // Get me all the previous filter chip string lengths and add them up and compare them to the maximumFilterChipWordLength
            // If the sum is greater than the maximumFilterChipWordLength, then substring the filter chip string to the maximumFilterChipWordLength and add an ellipsis
            // If the sum is less than the maximumFilterChipWordLength, then return a FilterChip with the label of the filter chip string
            // There probably can only be a maximum of 2 chips before the ellipsis

            const normalizeField = headerNameMap[field];
            const string = `${normalizeField} ${operator} ${value ? value : ""}`;

            if (index === 0) {
              return (
                <Fragment key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      borderRadius: "6px",
                      backgroundColor: "#EEF1FB",
                      border: "1px solid #BCC8F5",
                      padding: "4px 6px 4px 4px",
                      alignItems: "center",
                    }}
                  >
                    <FilterDownIcon sx={{ cursor: "pointer" }} onClick={() => setOpen(true)} />
                    <Typography color="#142E90">{data?.filters?.items.length}</Typography>
                  </Box>
                  <FilterChip key={index} label={string} />
                </Fragment>
              );
            }
            if (index === 5) {
              return <FilterChip key={index} label="..." />;
            } else {
              return <FilterChip key={index} label={string} />;
            }
          })}
          {data?.filters?.items?.length === 0 && <FilterChip subtitle label="No filters applied" />}
        </Box> */}
        <DataSourceNodeTable>
          <FilterTableRow>
            {consolidatedValues &&
              consolidatedValues.map((item, index) => {
                return (
                  <Typography variant="body1" fontSize="12px" key={index}>
                    {item.description}
                  </Typography>
                );
              })}
            {!consolidatedValues && (
              <>
                <Typography variant="body1" fontSize="12px">
                  Transaction %
                </Typography>
                <Typography variant="body1" fontSize="12px">
                  Count
                </Typography>
                <Typography variant="body1" fontSize="12px">
                  Amount EUR
                </Typography>
              </>
            )}
          </FilterTableRow>
          <FilterTableRow>
            {consolidatedValues &&
              consolidatedValues.map((item, index) => {
                const fontWeight = item.key === "Transaction %" ? "700" : "400";
                return (
                  <Typography variant="body1" fontSize="12px" fontWeight={fontWeight} key={index}>
                    {item.key === "amount_eur"
                      ? formatNumberWithCommas(item.value)
                      : formatNumberWithoutCommas(item.value)}
                  </Typography>
                );
              })}
            {!consolidatedValues && (
              <>
                <Typography variant="body1" fontSize="12px" fontWeight={700}>
                  100%
                </Typography>
                <Typography variant="body1" fontSize="12px" fontWeight={700}>
                  0
                </Typography>
                <Typography variant="body1" fontSize="12px" fontWeight={700}>
                  0
                </Typography>
              </>
            )}
          </FilterTableRow>
        </DataSourceNodeTable>
        <AddPlaceholderHandle
          type="source"
          parentId={id}
          position={Position.Right}
          id="continuationSource"
          isConnectable={isConnectable}
        />
        <AddFilterHandle
          type="source"
          parentId={id}
          position={Position.Bottom}
          $showIcon
          id="groupContinuationSource"
          isConnectable={isConnectable}
        />
      </Box>
    </div>
  );
};

export default FilterNodeCore;
