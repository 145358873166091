import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import StyledTableCell from "../../../../components/Styled/StyledTableCell";
import StyledTableRow from "../../../../components/Styled/StyledTableRow";
import TaskDrawer from "./TaskDrawer";
import StyledChip from "../../../../components/Styled/StyledChip";
import TasksHeader from "./TasksHeader";
import { TASK_STATE } from "../constants";
import EditableDateInput from "./EditableDateInput";
import { TaskDto } from "../../../../spec/bo";
import { useTask } from "../hooks/useTask";
import TaskReviewerCell from "./TaskReviewerCell";
import TaskPreparerCell from "./TaskPreparerCell";

export type TaskTableProps = {
  tasks: TaskDto[];
  selectedMonth: string;
};

const TaskTable: React.FC<TaskTableProps> = ({ tasks, selectedMonth }) => {
  const { selectedTaskWithEvents, selectTask, signTaskReviewer } = useTask();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    setIsDrawerOpen(!!selectedTaskWithEvents);
  }, [selectedTaskWithEvents]);

  const handleRowClick = (taskUuid: string) => {
    selectTask(taskUuid);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    selectTask(null);
  };

  const handleReviewerSign = async (taskUuid: string) => {
    try {
      await signTaskReviewer(taskUuid);
    } catch (error) {
      console.error("Error signing task:", error);
    }
  };

  const tableHeaders = [
    { label: "Task", width: "15%", minWidth: "100px", hasDivider: false },
    { label: "Category", width: "6%", minWidth: "80px", hasDivider: false },
    { label: "State", width: "6%", minWidth: "80px", hasDivider: true },
    { label: "Due Date", width: "6%", minWidth: "100px", hasDivider: true },
    { label: "Preparer", width: "8%", minWidth: "120px", hasDivider: true },
    { label: "Reviewer", width: "8%", minWidth: "120px", hasDivider: true },
    { label: "Transactions", width: "6%", minWidth: "80px", hasDivider: false },
    { label: "Amount EUR", width: "6%", minWidth: "80px", hasDivider: false },
  ];

  return (
    <>
      <TableContainer>
        <TasksHeader period={selectedMonth} />
        <Table aria-label="task table" style={{ tableLayout: "fixed", width: "100%" }}>
          <TableHead>
            <TableRow sx={{ borderBottom: "1px solid lightgrey" }}>
              {tableHeaders.map((header, index) => (
                <StyledTableCell
                  key={index}
                  hasDivider={header.hasDivider}
                  style={{
                    width: header.width,
                    minWidth: header.minWidth,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.length > 0 ? (
              tasks.map(task => {
                return (
                  <StyledTableRow key={task.uuid}>
                    <StyledTableCell
                      onClick={() => handleRowClick(task.uuid)}
                      style={{ width: tableHeaders[0].width }}
                    >
                      <Tooltip title={"View task"}>
                        <Typography noWrap>{task.name}</Typography>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: tableHeaders[1].width }}>
                      <StyledChip type="grey" label="Exports" />
                    </StyledTableCell>
                    <StyledTableCell hasDivider style={{ width: tableHeaders[2].width }}>
                      <StyledChip type="blue" label={TASK_STATE.TODO} />
                    </StyledTableCell>
                    <StyledTableCell hasDivider style={{ width: tableHeaders[3].width }}>
                      <EditableDateInput task={task} />
                    </StyledTableCell>
                    <StyledTableCell
                      hasDivider
                      style={{
                        width: tableHeaders[4].width,
                        minWidth: tableHeaders[4].minWidth,
                        overflow: "hidden",
                      }}
                    >
                      <TaskPreparerCell task={task} />
                    </StyledTableCell>
                    <StyledTableCell
                      hasDivider
                      style={{
                        width: tableHeaders[5].width,
                        minWidth: tableHeaders[5].minWidth,
                        overflow: "hidden",
                      }}
                    >
                      <TaskReviewerCell
                        task={task}
                        onApprovalChange={() => handleReviewerSign(task.uuid)}
                      />
                    </StyledTableCell>
                    <StyledTableCell style={{ width: tableHeaders[6].width }}>
                      {task.transaction_count}
                    </StyledTableCell>
                    <StyledTableCell style={{ width: tableHeaders[7].width }}>
                      {task.transaction_amount_eur}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <TableRow style={{ height: "120px", borderBottom: "1px solid lightgrey" }}>
                <StyledTableCell colSpan={tableHeaders.length} align="center">
                  <Typography
                    variant="body1"
                    fontSize={12}
                    fontWeight={400}
                    color="rgba(0,0,0,0.6)"
                  >
                    There are no tasks for {selectedMonth}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TaskDrawer open={isDrawerOpen} onClose={handleCloseDrawer} />
    </>
  );
};

export default TaskTable;
