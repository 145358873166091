import React, { createContext, useCallback, useState } from "react";
import { TaskDto, TaskUpdateDto, TaskWithEventsDto } from "../../../../spec/bo";
import { boApiClient } from "../../../../core/api";

type TaskContextType = {
  tasks: TaskDto[];
  selectedTaskWithEvents: TaskWithEventsDto | null;
  isLoading: boolean;
  error: Error | null;
  fetchTasks: () => Promise<void>;
  updateTask: (taskUuid: string, updates: TaskUpdateDto) => Promise<TaskWithEventsDto>;
  selectTask: (taskUuid: string) => Promise<void>;
  addComment: (taskId: string, comment: string) => Promise<void>;
  signTaskPreparer: (taskUuid: string) => Promise<TaskWithEventsDto>;
  signTaskReviewer: (taskUuid: string) => Promise<TaskWithEventsDto>;
};

export const TaskContext = createContext<TaskContextType | undefined>(undefined);

export const TaskProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tasks, setTasks] = useState<TaskDto[]>([]);
  const [selectedTaskWithEvents, setSelectedTaskWithEvents] = useState<TaskWithEventsDto | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchTasks = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const fetchedTasks = await boApiClient.tasks.getTasks();
      setTasks(fetchedTasks);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("An unknown error occurred"));
    } finally {
      setIsLoading(false);
    }
  }, []);

  const signTaskPreparer = async (taskUuid: string) => {
    try {
      const response = await boApiClient.tasks.signTaskPreparer(taskUuid);
      updateTaskState(response);
      return response;
    } catch (error) {
      console.error("Failed to sign task as preparer:", error);
      throw error;
    }
  };

  const signTaskReviewer = async (taskUuid: string) => {
    try {
      const response = await boApiClient.tasks.signTaskReviewer(taskUuid);
      updateTaskState(response);
      return response;
    } catch (error) {
      console.error("Failed to sign task as reviewer:", error);
      throw error;
    }
  };

  const updateTaskState = (updatedTask: TaskWithEventsDto) => {
    setTasks(prevTasks =>
      prevTasks.map(task => (task.uuid === updatedTask.task.uuid ? updatedTask.task : task))
    );

    if (selectedTaskWithEvents && selectedTaskWithEvents.task.uuid === updatedTask.task.uuid) {
      setSelectedTaskWithEvents(updatedTask);
    }
  };

  const updateTask = async (taskUuid: string, updates: TaskUpdateDto) => {
    try {
      const response = await boApiClient.tasks.taskUpdate(taskUuid, updates);

      setTasks(prevTasks =>
        prevTasks.map(task => (task.uuid === response.task.uuid ? response.task : task))
      );

      if (selectedTaskWithEvents && selectedTaskWithEvents.task.uuid === taskUuid) {
        setSelectedTaskWithEvents(response);
      }

      return response;
    } catch (error) {
      console.error("Failed to update task:", error);
      throw error;
    }
  };

  const addComment = async (taskId: string, comment: string) => {
    try {
      const response = await boApiClient.tasks.taskCommentCreate(taskId, { comment });
      if (response && response.events) {
        setSelectedTaskWithEvents(prevState => ({
          ...prevState!,
          events: response.events,
        }));
      }
    } catch (error) {
      console.error("Failed to add comment:", error);
      throw error;
    }
  };

  const selectTask = async (taskUuid: string | null) => {
    if (!taskUuid) {
      setSelectedTaskWithEvents(null);
      return;
    }

    try {
      const response: TaskWithEventsDto = await boApiClient.tasks.getTaskWithEvents(taskUuid);
      if (response.task) {
        setSelectedTaskWithEvents({
          task: response.task,
          events: response.events || [],
        });
      }
    } catch (error) {
      console.error("Failed to fetch task with events:", error);
    }
  };

  return (
    <TaskContext.Provider
      value={{
        tasks,
        selectedTaskWithEvents,
        isLoading,
        error,
        fetchTasks,
        updateTask,
        selectTask,
        addComment,
        signTaskPreparer,
        signTaskReviewer,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};
