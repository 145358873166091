import { Box, Button, CircularProgress } from "@mui/material";

export const showErrorNotification = (description, enqueueSnackbar, closeSnackbar) => {
  const action = key => (
    <>
      <Button
        sx={{ color: "inherit" }}
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    </>
  );

  enqueueSnackbar(description, {
    action,
    persist: true,
    variant: "error",
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
  });
};

export const showProgressNotification = (title, enqueueSnackbar, options = {}) => {
  const action = key => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <CircularProgress color="inherit" size={20} />
      </Box>
    );
  };

  return enqueueSnackbar(title, {
    action,
    persist: true,
    variant: "info",
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    ...options,
  });
};

export const showSuccessNotification = (status, enqueueSnackbar, closeSnackbar) => {
  const action = key => (
    <>
      <Button
        sx={{ color: "inherit" }}
        onClick={() => {
          closeSnackbar(key);
        }}
      ></Button>
    </>
  );

  enqueueSnackbar(status, {
    action,
    variant: "success",
    autoHideDuration: 5000,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
  });
};

export const showWarningNotification = (status, enqueueSnackbar, closeSnackbar) => {
  const action = key => (
    <>
      <Button
        sx={{ color: "inherit" }}
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Ok
      </Button>
    </>
  );

  enqueueSnackbar(status, {
    action,
    variant: "warning",
    persist: true,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
  });
};

export const showNodeActionNotification = (description, enqueueSnackbar) => {
  enqueueSnackbar(description, {
    persist: false,
    autoHideDuration: 3000,
    variant: "default",
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
  });
};

export const showSuccessWithCustomButton = (
  description,
  buttonText,
  buttonFunction,
  enqueueSnackbar,
  closeSnackbar,
  onExited
) => {
  const action = key => (
    <>
      <Button
        sx={{ color: "inherit" }}
        onClick={() => {
          buttonFunction();
          closeSnackbar(key);
        }}
      >
        {buttonText}
      </Button>
    </>
  );

  enqueueSnackbar(description, {
    action,
    variant: "success",
    autoHideDuration: 5000,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    onExited: onExited,
  });
};
