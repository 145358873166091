import React, { useState } from "react";
import { Box, Tooltip } from "@mui/material";
import StyledSwitch from "../../../../components/Styled/StyledSwitch";
import ApprovalModal from "./ApprovalModal";
import { TaskDto } from "../../../../spec/bo";
import StyledAvatar from "../../../../components/Styled/StyledAvatar";
import { createAvatarContent, UserInfo } from "../utils/taskCellUtils";

interface ReviewerCellProps {
  task: TaskDto;
  onApprovalChange: () => Promise<void>;
}

const TaskReviewerCell: React.FC<ReviewerCellProps> = ({ task, onApprovalChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isApproved = !!task.reviewed_at;
  const displayName = task.can_review ? "You" : "User";

  const handleSwitchChange = () => {
    if (task.can_review) {
      setIsModalOpen(true);
    }
  };

  const handleModalApprove = async () => {
    await onApprovalChange();
    setIsModalOpen(false);
  };

  return (
    <>
      <Tooltip
        title={task.can_review ? "Give approval signature" : "You don't have permission to sign"}
      >
        <Box display="flex" alignItems="center" flexWrap="wrap" minWidth="120px" maxWidth="100%">
          <StyledAvatar>
            {createAvatarContent(
              false,
              task.can_review ? "You" : task.reviewer?.name,
              task.can_review,
              null
            )}
          </StyledAvatar>
          <UserInfo name={displayName} />
          <Box flexShrink={0}>
            <StyledSwitch
              size="small"
              checked={isApproved}
              onChange={handleSwitchChange}
              disabled={!task.can_review}
              sx={{
                "& .MuiSwitch-switchBase.Mui-disabled": {
                  opacity: 0.5,
                },
                "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
                  opacity: 0.3,
                },
              }}
            />
          </Box>
        </Box>
      </Tooltip>

      <ApprovalModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onApprove={handleModalApprove}
        task={task}
      />
    </>
  );
};

export default TaskReviewerCell;
