import { Position } from "@xyflow/react";
import SingleConnectionHandle from "../handleTypes/Handle";
import FilterNodeCore from "./FilterNodeCore";

const FilterNodeRoot = props => {
  const { isConnectable, id } = props;
  const isRoot = id.includes("root");

  return (
    <>
      <SingleConnectionHandle
        type="target"
        position={Position.Left}
        id="continuationTarget"
        isConnectable={1}
      />

      <FilterNodeCore {...props} isRoot={isRoot} />
    </>
  );
};

export default FilterNodeRoot;
