import React from "react";
import { Box, Tooltip } from "@mui/material";
import robotIcon from "../../../../assets/icons/robotIcon.svg";
import { TaskDto } from "../../../../spec/bo";
import StyledAvatar from "../../../../components/Styled/StyledAvatar";
import { createAvatarContent, UserInfo } from "../utils/taskCellUtils";

interface PreparerCellProps {
  task: TaskDto;
}

const PreparerCell: React.FC<PreparerCellProps> = ({ task }) => {
  const displayName = task.is_automatic
    ? "Automatic"
    : (task.can_prepare ? task.preparer?.name : "User") || "User";

  return (
    <Tooltip title="Task preparer">
      <Box display="flex" alignItems="center" flexWrap="wrap" minWidth="120px" maxWidth="100%">
        <StyledAvatar>
          {createAvatarContent(
            task.is_automatic,
            task.preparer?.name ?? "User",
            task.can_prepare,
            <img alt="A" src={robotIcon} width="16.6px" height="16.6px" />
          )}
        </StyledAvatar>
        <UserInfo name={displayName} />
      </Box>
    </Tooltip>
  );
};

export default PreparerCell;
