import { Box, ClickAwayListener, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useExportClick } from "../hooks/useExportClick";
import { useFilterClick } from "../hooks/useFilterClick";
import useSpreadsheetClick from "../hooks/useSpreadsheetClick";

interface IAddPlaceholderProps {
  parentId: string;
  openHook: {
    open: boolean;
    setOpen: (open: boolean) => void;
  };
}

const AddPlaceholder = ({ parentId, openHook }: IAddPlaceholderProps) => {
  const { open, setOpen } = openHook;

  const filterClick = useFilterClick(parentId);
  const exportClick = useExportClick(parentId);
  const spreadsheetClick = useSpreadsheetClick(parentId);

  useEffect(() => {
    console.log("open", open);
  }, [open]);

  if (!open) {
    return null;
  }
  console.log(parentId);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box
        sx={{
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              borderRadius: "4px",
              minWidth: "160px",
              py: 0.5,
              backgroundColor: "#FFF",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
              top: "0",
              left: "0",
            }}
          >
            <Box
              sx={{
                py: 1,
                px: 1.5,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <Typography
                onClick={() => {
                  filterClick();
                  setOpen(false);
                }}
              >
                Filter transactions
              </Typography>
            </Box>
            <Box
              sx={{
                py: 1,
                px: 1.5,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <Typography
                onClick={() => {
                  console.log("clicked export");
                  exportClick();
                  setOpen(false);
                }}
              >
                Export to accounting
              </Typography>
            </Box>
            <Box
              sx={{
                py: 1,
                px: 1.5,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <Typography
                onClick={() => {
                  spreadsheetClick();
                  setOpen(false);
                }}
              >
                Spreadsheet
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default AddPlaceholder;
