import CancelIcon from "@mui/icons-material/Cancel";
import { Box, IconButton, Typography } from "@mui/material";
import { NodeProps } from "@xyflow/react";
import { useShallow } from "zustand/react/shallow";
import useConfirm from "../../../../hooks/useConfirm";
import useDeleteGroupClick from "../hooks/useDeleteGroupClick";
import useStore, { createRFSelector } from "../store/ReactflowStore";

const selector = createRFSelector("hoveredNode");
const GroupNode = (props: NodeProps) => {
  const { id } = props;
  const { hoveredNode } = useStore(useShallow(selector));
  const isHovered = hoveredNode && hoveredNode.id === id;

  const isRoot = id.includes("root");
  const onDelete = useDeleteGroupClick(id);
  const [ConfirmDialog, confirmDelete] = useConfirm(
    "Delete this node?",
    "Deleting this node will also remove all subsequent nodes, if any. Ensure this is what you want to do before proceeding."
  );

  const handleDelete = async () => {
    const confirm = await confirmDelete();
    if (confirm) onDelete();
  };

  return (
    <div>
      <ConfirmDialog />
      {!isRoot && (
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1,
            cursor: "pointer",
          }}
          size="small"
          onClick={handleDelete}
        >
          <CancelIcon fontSize="small" sx={{ color: "#BFBFBF" }} />
        </IconButton>
      )}
      <Box sx={{ position: "absolute", top: "18px", left: "16px" }}>
        <Typography variant="body2" fontWeight="700" fontSize="12px">
          Filter transactions
        </Typography>
      </Box>
    </div>
  );
};

export default GroupNode;
