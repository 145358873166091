import { Auth0Provider } from "@auth0/auth0-react";
import { Slide } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
import { createRoot } from 'react-dom/client';

import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://02c6ff77b80a41e29846288212d3cbcc@o1343809.ingest.sentry.io/6616211",
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maxReplayDuration: 60000,
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    ignoreErrors: ["AbortError"],
  });
}

const callbackExcludedPaths = ["/app/looker", "/app/integrations/redirect"];



const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render( <>
<Auth0Provider
    domain="iter8.eu.auth0.com"
    clientId="WTQ0t5tMGX8ePcwJaERkT6eZZi5Apti3"
    redirectUri={window.location.origin + "/login"}
    audience="editor-api.iter8.ai"
    scope="editor_user"
    cacheLocation="localstorage"
    skipRedirectCallback={callbackExcludedPaths.includes(window.location.pathname)}
  >
    <Router>
      <SnackbarProvider TransitionComponent={Slide}>
        <ErrorBoundary fallback={<p>Something went wrong</p>}>
          <App />
        </ErrorBoundary>
      </SnackbarProvider>
    </Router>
  </Auth0Provider></>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// Test comment
