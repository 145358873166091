import { Node } from "@xyflow/react";
import { v4 as uuidv4 } from "uuid";
import nodeTypes from "../nodeTypes";

export interface NodePosition {
  x: number;
  y: number;
}

export type ElkNodeData = {
  label: string;
  sourceHandles: { id: string }[];
  targetHandles: { id: string }[];
};

export interface ExtendedNode extends Node {
  initialHeight: number;
  initialWidth: number;
}

export type ElkNode = Node<ElkNodeData, "elk">;

export const GAP_BETWEEN_NODES: number = 50;
export const NODE_WIDTHS: number = 181;
export const GROUPED_NODE_PADDING_POSITION = 16;
export const GROUPED_NODE_INITIAL_WIDTH = 430;
export const DATA_SOURCE_NODE_INITIAL_WIDTH = 426;
export const ADD_FILTER_NODE_WIDTH: number = 118;
export const EXPORT_NODE_HEIGHT = 83;
export const FILTER_NODE_TYPES = ["filterNodeRoot", "filterNodeSecondary", "filterNodeRemaining"];
export const NODE_INITIAL_WIDHTS = {
  groupNode: 430,
  dataMatchingSource: 426,
  filterNodeRoot: 438,
  filterNodeSecondary: 438,
  filterNodeRemaining: 438,
  xeroExportTarget: 181,
  spreadsheetNode: 0,
};

export const getNodeInitialWidth = (type: string): number => {
  if (!NODE_INITIAL_WIDHTS[type]) return 0;
  return NODE_INITIAL_WIDHTS[type];
};

export const createRootFilterNode = (parentNode: string, position: NodePosition): ExtendedNode => {
  const rootFilterNode: ExtendedNode = {
    id: uuidv4(),
    position: position,
    type: "filterNodeRoot",
    draggable: false,
    parentId: parentNode,
    extent: "parent",
    data: { label: "Filter" },
    initialHeight: 180,
    initialWidth: 438,
  };
  return rootFilterNode;
};

export const createSecondaryFilterNode = (parentNode: string, position: NodePosition): Node => {
  const node: Node = {
    id: uuidv4(),
    position: position,
    draggable: false,
    type: "filterNodeSecondary",
    extent: "parent",
    parentId: parentNode,
    data: { label: "Filter" },
    initialHeight: 180,
    initialWidth: 438,
  };

  return node;
};

export const createPlaceholderNode = (position: NodePosition): Node => {
  let placeHolderNode: Node = {
    id: uuidv4(),
    position: position,
    type: "placeholder",
    data: { label: "Add" },
    initialHeight: 53,
    initialWidth: 181,
  };
  return placeHolderNode;
};

export const createRemainingTxNode = (parentNode: string, position: NodePosition): Node => {
  const remainingTxNode: Node = {
    id: uuidv4(),
    position: position,
    type: "filterNodeRemaining",
    extent: "parent",
    parentId: parentNode,
    data: { label: "Remaining transactions" },
    initialHeight: 138,
    initialWidth: 181,
  };
  return remainingTxNode;
};

export const createGroupNode = (position: NodePosition): Node => {
  const groupNode: Node = {
    id: uuidv4(),
    position: position,
    type: "groupNode",
    data: { label: "groupNode" },
    initialHeight: 450,
    initialWidth: 430,
    style: {
      width: "430px",
      border: "1px dashed rgba(0, 0, 0, 0.24)",
      height: "450px",
      borderRadius: "4px",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  };
  return groupNode;
};

export const createExportNode = (position: NodePosition): Node => {
  const exportNode = {
    id: uuidv4(),
    position: position,
    type: "xeroExportTarget",
    data: {
      label: "Export to accounting",
      exportSettings: {
        period: "daily",
        preferredSource: "primary",
        taskMonthAssignment: "lastDay",
        debitAccount: "",
        taskPreparers: [],
        taskReviewers: [],
        taskReviewerType: "",
        taskPreparerType: "",
        creditAccount: "",
      },
    },
  };
  return exportNode;
};

export const createSpreadsheetNode = (position: NodePosition): Node => {
  const spreadsheetNode = {
    id: uuidv4(),
    position: position,
    type: "spreadsheetNode",
    data: {
      label: "Export data to Google Sheet",
      exportSettings: {
        exportAs: "daily",
        templateSheetName: "",
        frequency: "scheduled",
        interval: "oneChoice",
        folderName: "folderName",
        tabName: "IMPORTED_DATA",
        columnsToExport: [],
      },
    },
  };
  return spreadsheetNode;
};
