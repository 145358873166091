import { NodeProps } from "@xyflow/react";
import { useShallow } from "zustand/react/shallow";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import { createEdge } from "../utils/edges";
import { createSpreadsheetNode } from "../utils/nodes";

const selector = createRFSelector(
  "getNode",
  "setNodes",
  "setEdges",
  "nodes",
  "edges",
  "getIncomers"
);

export function useSpreadsheetClick(id: NodeProps["id"]) {
  const { getNode, setNodes, setEdges, nodes, edges, getIncomers } = useStore(useShallow(selector));

  const onClick = () => {
    const currentNode = getNode(id);

    if (!currentNode) {
      return;
    }
    const [connectedNode] = getIncomers(currentNode);
    console.log(connectedNode.position.y);

    const spreadsheetNodePosition = {
      x: currentNode.position.x,
      y: connectedNode.position.y,
    };
    const spreadsheetNode = createSpreadsheetNode(spreadsheetNodePosition);
    const placeholderEdge = createEdge(connectedNode.id, spreadsheetNode.id);

    const newNodes = nodes.filter(node => node.id !== id).concat([spreadsheetNode]);
    setNodes(newNodes);
    const newEdges = edges.concat([placeholderEdge]);
    setEdges(newEdges);
  };

  return onClick;
}

export default useSpreadsheetClick;
