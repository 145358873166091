import { Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

interface StyledAvatarProps {
  children: React.ReactNode;
}

const StyledAvatar: React.FC<StyledAvatarProps> = ({ children }) => {
  const AvatarComponent = styled(Avatar)({
    width: 24,
    height: 24,
    fontSize: 12,
    flexShrink: 0,
    marginRight: 8,
    backgroundColor: "#D9D9D9",
    textTransform: "uppercase",
  });

  return <AvatarComponent>{children}</AvatarComponent>;
};

export default StyledAvatar;
