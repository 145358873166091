import { Box, Button, Typography } from "@mui/material";
import { NodeProps, Position } from "@xyflow/react";
import SingleConnectionHandle from "../handleTypes/Handle";
import Sidebar from "../components/Sidebar";
import ExportGoogleSheet, { IExportSettings } from "../components/ExportToGoogleSheet";
import { useState } from "react";
import EditLineIcon from "@mui/icons-material/BorderColor";
interface IExportProps extends NodeProps {
  id: string;
  data: {
    label: string;
    exportSettings: IExportSettings;
  };
}

const SpreadsheetNode = (props: IExportProps) => {
  const { data, id } = props;
  const { exportSettings } = data;
  const { label } = data;
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.12)",
          p: 2,
          backgroundColor: "white",
          borderRadius: "4px",
          width: "432px",
          zIndex: 9,
        }}
      >
        <Sidebar openHook={{ open, setOpen }}>
          <ExportGoogleSheet id={id} data={data} />
        </Sidebar>
        <SingleConnectionHandle
          type="target"
          position={Position.Left}
          id="exportTarget"
          isConnectable={1}
        />
        <Typography variant="h5" fontSize="12px" fontWeight="700">
          {label}
        </Typography>
        <Button
          variant="text"
          size="small"
          onClick={() => setOpen(true)}
          startIcon={<EditLineIcon />}
        >
          Edit
        </Button>
        <Box sx={{ zIndex: 9, my: 1 }}></Box>
      </Box>
    </>
  );
};

export default SpreadsheetNode;
