import React from "react";
import { Box, Typography } from "@mui/material";
import StyledAvatar from "../../../../components/Styled/StyledAvatar";
import { EventDto } from "../../../../spec/bo";
import dayjs from "dayjs";

type TaskEventProps = {
  event: EventDto;
  isLast: boolean;
};

const TaskEvent: React.FC<TaskEventProps> = ({ event, isLast }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2, position: "relative" }}>
      <StyledAvatar>{event.name.charAt(0)}</StyledAvatar>
      <Box sx={{ ml: 2, flex: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="body2" lineHeight={1.6} fontSize={12}>
            {event.name}
          </Typography>
          <Typography
            variant="body2"
            color="rgba(0, 0, 0, 0.35)"
            lineHeight={1.4}
            fontSize={10}
            sx={{ ml: 1 }}
          >
            {dayjs.utc(event.created_at).local().format("DD.MM.YYYY HH:mm")}
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary" lineHeight={1.6} fontSize={12}>
          User
        </Typography>
        {event.description && (
          <Box sx={{ backgroundColor: "rgba(0,0,0,0.04)", p: 1, mt: 1 }}>
            <Typography fontSize={12} lineHeight={1.6} variant="body2">
              {event.description}
            </Typography>
          </Box>
        )}
      </Box>

      {!isLast && (
        <Box
          sx={{
            position: "absolute",
            top: 20,
            left: 9.5,
            height: "calc(100%)",
            width: "1px",
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            zIndex: -1,
          }}
        />
      )}
    </Box>
  );
};

export default TaskEvent;
