import { RadioProps, Radio as MuiRadio } from "@mui/material";

interface IRadio extends RadioProps {}

const Radio = (props: IRadio) => {
  const { ...rest } = props;

  return (
    <MuiRadio
      size="small"
      sx={{
        "&.Mui-checked": {
          color: "#142E90",
        },
      }}
      {...rest}
    />
  );
};

export default Radio;
