import { Box } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { ReactElement, useCallback, useEffect } from "react";
import { useSessionStorage } from "usehooks-ts";
import { createColumns } from "../utils/table";
import FilterToolbar from "./FilterToolbar";


interface IFilterProps {
  samples: any;
  nodeId: string;
  filters?: any;
}

const Filter = (props: IFilterProps): ReactElement => {
  const { samples, nodeId, filters } = props;
  const [storedFilters, setStoredFilters] = useSessionStorage(`filters-${nodeId}`, filters);
  const apiRef = useGridApiRef();
  const columns = createColumns(storedFilters);

  const onFilterChange = useCallback(
    filterModel => {
      setStoredFilters(filterModel);
    },
    [samples, nodeId]
  );

  useEffect(() => {
    if (!filters && !storedFilters) {
      setStoredFilters({ items: [] });
    } else {
      setStoredFilters(filters);
    }
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100%", overflow: "scroll" }}>
      <DataGridPro
        rows={samples}
        getRowId={row => row.composite_key}
        apiRef={apiRef}
        autoHeight
        rowHeight={40}
        rowSelection={false}
        columns={columns}
        slots={{ toolbar: FilterToolbar }}
        slotProps={{ toolbar: { nodeId, filters: storedFilters } }}
        filterModel={storedFilters}
        onFilterModelChange={(model, details) => {
          onFilterChange(model);
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100]}
        checkboxSelection={false}
        disableRowSelectionOnClick
      />
    </Box>
  );
};
export default Filter;
