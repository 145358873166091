import React, { useState } from "react";
import { Box, Button, CircularProgress, Modal, Typography } from "@mui/material";
import { TaskDto } from "../../../../spec/bo";

interface ApprovalModalProps {
  open: boolean;
  onClose: () => void;
  onApprove: () => void;
  task: TaskDto;
}

const ApprovalModal: React.FC<ApprovalModalProps> = ({ open, onClose, onApprove, task }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleApprove = async () => {
    setIsLoading(true);
    try {
      await onApprove();
      onClose();
    } catch (error) {
      console.error("Error during approval:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
        }}
      >
        <Typography variant="h6" component="h2">
          Give approval signature
        </Typography>
        <Typography sx={{ mt: 2 }}>{task.name}</Typography>
        <Box sx={{ backgroundColor: "rgba(0,0,0,0.04)", p: 1, mt: 1 }}>
          {task.description && (
            <Typography fontSize={12} lineHeight={1.6} variant="body2">
              {task.description}
            </Typography>
          )}
        </Box>
        <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={onClose}
            sx={{ mr: 2, border: "1px #BCC8F5 solid", borderRadius: "4px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleApprove}
            variant="contained"
            disabled={isLoading}
            sx={{ borderRadius: "4px" }}
          >
            {isLoading ? <CircularProgress size={24} /> : "Sign"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApprovalModal;
