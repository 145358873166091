import { Box, Tooltip } from "@mui/material";
import {
  getConnectedEdges,
  Handle,
  useNodeId,
  useStore as useStoreReactFlow,
  useUpdateNodeInternals,
} from "@xyflow/react";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";
import { ReactComponent as AddFilterIcon } from "../../../../assets/icons/workflowsAddIcon.svg";
import useAddFilterClick from "../hooks/useAddFilterClick";
import useStore, { createRFSelector } from "../store/ReactflowStore";

const selector = createRFSelector("edges");

const AddFilterHandle = props => {
  const { showIcon = false, parentId } = props;
  const onClick = useAddFilterClick(parentId);
  const { edges } = useStore(useShallow(selector));
  const { nodeLookup } = useStoreReactFlow(useShallow(state => ({ nodeLookup: state.nodeLookup })));
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();

  const isHandleConnectable = useCallback(() => {
    if (typeof props.isConnectable === "function") {
      const node = nodeLookup.get(nodeId);
      const connectedEdges = getConnectedEdges([node], edges);
      updateNodeInternals(nodeId);
      return props.isConnectable({ node, connectedEdges });
    }

    if (typeof props.isConnectable === "number") {
      const node = nodeLookup.get(nodeId);
      const connectedEdges = getConnectedEdges([node], edges);
      updateNodeInternals(nodeId);
      return connectedEdges.length < props.isConnectable;
    }

    return props.isConnectable;
  }, [nodeLookup, edges, nodeId, props.isConnectable, updateNodeInternals]);

  return (
    <Handle
      {...props}
      isConnectable={isHandleConnectable}
      style={{ zIndex: 1, visibility: "hidden" }}
    >
      <Tooltip title="Add Filter between" placement="top">
        <Box sx={{ visibility: "visible", cursor: "pointer" }} onClick={onClick}>
          <Box
            sx={{
              position: "relative",
              left: "-7px",
              top: "-7px",
              visibility: "visible",
              pointerEvents: "none",
            }}
          >
            <AddFilterIcon />
          </Box>
        </Box>
      </Tooltip>
    </Handle>
  );
};

export default AddFilterHandle;
