import { IconButton } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const RunButton = props => {
  return (
    <IconButton
      {...props}
      sx={{
        borderRadius: "4px",
        height: "28px",
        width: "28px",
        backgroundColor: "rgba(29, 66, 201, 1)",
        "&:hover": {
          backgroundColor: "rgba(29, 66, 201, 0.8)",
        },
      }}
      size="small"
    >
      <PlayArrowIcon sx={{ fill: "white" }} />
    </IconButton>
  );
};

export default RunButton;
