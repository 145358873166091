import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, MouseEventHandler, ReactElement, Ref, useState } from "react";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IEditWorkflowNameDialog {
  success: (data: Object) => Promise<void>;
  cancel: MouseEventHandler;
  workflowName: string;
}

const EditWorkflowNameDialog = ({ success, cancel, workflowName }: IEditWorkflowNameDialog) => {
  const [inputValue, setInputValue] = useState<string>(workflowName);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Dialog open={true} TransitionComponent={Transition} onClose={cancel}>
      <Box sx={{ position: "absolute", right: "0px", top: "0px", zIndex: "2" }}>
        <IconButton onClick={cancel} sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.25)" }}>
          <CancelIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ width: "calc(312px - 32px)", p: 2 }}>
        <DialogTitle sx={{ p: 0, mb: 1 }}>Edit workflow name</DialogTitle>
        <DialogContent sx={{ p: 0, mb: 5 }}>
          <Box sx={{ width: "280px", display: "flex", flexDirection: "column", mt: 2 }}>
            <TextField
              label={""}
              placeholder={"Enter new workflow name"}
              value={inputValue}
              autoFocus
              onChange={e => {
                setInputValue(e.target.value);
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "flex-start", p: 0 }}>
          <LoadingButton
            variant="contained"
            data-action="Save workflow name [EditWorkflowNameDialog]"
            disabled={!inputValue}
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await success(inputValue);
              setLoading(false);
            }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditWorkflowNameDialog;
