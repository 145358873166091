import React from "react";
import { Box, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

export const createAvatarContent = (
  isAutomatic: boolean,
  name: string,
  canSign: boolean,
  Icon: React.ReactNode
) => {
  if (isAutomatic) {
    return Icon;
  }
  if (!canSign) {
    return <LockIcon sx={{ fontSize: 16 }} />;
  }
  return name.charAt(0).toUpperCase();
};

export const UserInfo: React.FC<{ name: string }> = ({ name }) => (
  <Box display="flex" alignItems="center" flexGrow={1} minWidth={0}>
    <Typography
      variant="body2"
      noWrap
      sx={{
        mr: 1,
        fontSize: 12,
        lineHeight: 1.6,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {name}
    </Typography>
  </Box>
);
