import { useEffect, useState } from "react";
import { TASK_TYPE } from "../constants";
import { generateMonths } from "../utils/generateMonths";

export const useSelectedMonthTaskType = () => {
  const [selectedMonthTaskType, setSelectedMonthTaskType] = useState<{
    month: string;
    taskType: string;
  }>({
    month: "",
    taskType: TASK_TYPE.EXPORT,
  });

  useEffect(() => {
    const months = generateMonths();
    if (months.length > 1) {
      setSelectedMonthTaskType(prev => ({
        ...prev,
        month: months[1],
      }));
    }
  }, []);

  const setSelectedMonth = (month: string) => {
    setSelectedMonthTaskType(prev => ({ ...prev, month }));
  };

  const setSelectedTaskType = (month: string, taskType: string) => {
    setSelectedMonthTaskType({ month, taskType });
  };

  return {
    selectedMonthTaskType,
    setSelectedMonth,
    setSelectedTaskType,
  };
};
