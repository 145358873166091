import { Position } from "@xyflow/react";
import SingleConnectionHandle from "../handleTypes/Handle";
import FilterNodeCore from "./FilterNodeCore";

const FilterNodeSecondary = props => {
  return (
    <>
      <SingleConnectionHandle position={Position.Top} type="target" id="groupContinuationTarget" />
      <FilterNodeCore {...props} isSecondary />
    </>
  );
};

export default FilterNodeSecondary;
