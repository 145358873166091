import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { TaskDto } from "../../../../spec/bo";
import StyledChip from "../../../../components/Styled/StyledChip";
import EditableDateInput from "./EditableDateInput";
import EditableDescription from "./EditableDescription";
import StyledSwitch from "../../../../components/Styled/StyledSwitch";
import StyledChipWithIcon from "../../../../components/Styled/StyledChipWithIcon";
import { NavLink } from "react-router-dom";
import TaskDetailsPreparerRow from "./TaskDetailsPreparerRow";
import TaskDetailsReviewerRow from "./TaskDetailsReviewerRow";

type TaskDetailsProps = {
  task: TaskDto | null;
};

const TaskDetails: React.FC<TaskDetailsProps> = ({ task }) => {
  if (!task) return null;

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h6" sx={{ marginBottom: 4 }}>
        {task.name}
      </Typography>

      <Typography variant="subtitle1" fontSize={12} sx={{ mb: 2 }}>
        Details
      </Typography>
      <Grid container spacing={2} sx={{ height: 40 }} alignItems="center">
        <Grid item xs={3}>
          <Typography fontSize={12} lineHeight={1.6} variant="body2" color="rgba(0, 0, 0, 0.6)">
            Recurring
          </Typography>
        </Grid>
        <Grid item xs={9}>
          {/* TODO: Will need to make this switch dynamic when users can create tasks*/}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <StyledSwitch size="small" checked={true} sx={{ mr: 2 }} />
              <Typography fontSize={12} variant="body2">
                Automatic
              </Typography>
            </Box>
            <NavLink
              to={"/app/tasks?uuid=dbdd8920-a40a-45fb-846e-7a45c767c196"}
              target="_blank"
              children={() => (
                <StyledChipWithIcon
                  label="Workflow"
                  iconType="workflow"
                  iconPosition="end"
                  sx={{ ml: "auto" }}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2} sx={{ height: 40 }} alignItems="center">
        <Grid item xs={3}>
          <Typography fontSize={12} lineHeight={1.6} variant="body2" color="rgba(0, 0, 0, 0.6)">
            Category
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <StyledChip type="grey" label="Exports" />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2} sx={{ height: 40 }} alignItems="center">
        <Grid item xs={3}>
          <Typography fontSize={12} lineHeight={1.6} variant="body2" color="rgba(0, 0, 0, 0.6)">
            Status
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <StyledChip type="green" label="Done" />
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2} sx={{ height: 40 }} alignItems="center">
        <Grid item xs={3}>
          <Typography fontSize={12} lineHeight={1.6} variant="body2" color="rgba(0, 0, 0, 0.6)">
            Month
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize={12} lineHeight={1.6} variant="body2">
            June 2024
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2} sx={{ height: 40 }} alignItems="center">
        <Grid item xs={3}>
          <Typography fontSize={12} lineHeight={1.6} variant="body2" color="rgba(0, 0, 0, 0.6)">
            Due date
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <EditableDateInput />
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2} sx={{ height: 40 }} alignItems="center">
        <Grid item xs={3}>
          <Typography fontSize={12} lineHeight={1.6} variant="body2" color="rgba(0, 0, 0, 0.6)">
            Description
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <EditableDescription />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle1" fontSize={12} fontWeight={700} sx={{ mb: 2 }}>
        Approvals
      </Typography>
      <Grid item xs={12}>
        <TaskDetailsPreparerRow task={task} />
      </Grid>
      <Divider sx={{ my: 2 }} />

      <Grid item xs={12}>
        <TaskDetailsReviewerRow task={task} />
      </Grid>

      <Divider sx={{ my: 2 }} />
    </Box>
  );
};

export default TaskDetails;
