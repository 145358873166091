import { Box, Button, Typography } from "@mui/material";

import { createPortal } from "react-dom";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import Filter from "./Filter";
import { useShallow } from "zustand/react/shallow";

interface IFilterSidebarProps {
  openHook: {
    open: boolean;
    setOpen: (value: boolean) => void;
  };
  id: string;
  data: any;
}

const selector = createRFSelector("nodes", "setNodes", "updateAndRunWorkflow");

const FilterSidebar = (props: IFilterSidebarProps) => {
  const { openHook, id, data } = props;
  const { open, setOpen } = openHook;
  const { samples, filters = { items: [] } } = data;

  const { updateAndRunWorkflow, nodes, setNodes } = useStore(useShallow(selector));

  const onClose = () => {
    const storedFilters = window.sessionStorage.getItem(`filters-${id}`);
    const jsonFilters = JSON.parse(storedFilters);
    const newNodes = nodes.map(item => {
      if (item.id !== id) return item;
      return {
        ...item,
        data: {
          ...item.data,
          filters: jsonFilters,
        },
      };
    });

    setNodes(newNodes);
    // updateAndRunWorkflow();
  };

  if (!open) return null;

  const PortalContent = () => {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          zIndex: 4,
          top: 0,
          left: 0,
          position: "absolute",
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
            cursor: "pointer",
          }}
          onClick={() => setOpen(false)}
        />
        <Box
          sx={{
            backgroundColor: "white",
            width: "90%",
            height: "90%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "28px 16px",
            zIndex: 100000000,
            border: "1px solid rgba(0, 0, 0, 0.12)",
            overflow: "scroll",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", py: 2, px: 3 }}>
            <Typography variant="h6">Apply filters</Typography>
            <Button
              variant="contained"
              onClick={() => {
                onClose();
                setOpen(false);
              }}
            >
              Done
            </Button>
          </Box>
          <Box sx={{ p: 2 }}>
            <Filter samples={samples} filters={filters} nodeId={id} />
          </Box>
        </Box>
      </Box>
    );
  };

  return createPortal(<PortalContent />, document.getElementById("workflows-container"));
};

export default FilterSidebar;
