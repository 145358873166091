import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import InputSelectMulti from "../../../../components/UI/Input/InputSelectMulti";
import Radio from "../../../../components/UI/Radio/Radio";
import { ConnectionDto, GetUserDto } from "../../../../spec/bo";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import { useShallow } from "zustand/react/shallow";

interface IExportProps {
  id: string;
  //   integration: ConnectionDto | null;
  data: {
    exportSettings: IExportSettings;
  };
  //   users: GetUserDto[];
}
export interface IExportSettings {
  exportAs: "daily" | "weekly" | "monthly" | "quarterly" | "yearly";
  templateSheetName: string;
  frequency: "scheduled" | "oneTime";
  interval: string;
  folderName: string;
  tabName: string;
  columnsToExport: string[];
}

const selector = createRFSelector("nodes", "setNodes");
const ExportGoogleSheet = (props: IExportProps): JSX.Element => {
  const { id, data } = props;
  const { exportSettings } = data;
  const {
    exportAs = "daily",
    templateSheetName = "",
    frequency = "scheduled",
    interval = "",
    folderName = "",
    tabName = "IMPORTED_DATA",
    columnsToExport = [],
  } = exportSettings;

  const { nodes, setNodes } = useStore(useShallow(selector));

  const [loading, setLoading] = useState<boolean>(false);

  const [settings, setSettings] = useState<IExportSettings>({
    exportAs: exportAs || "daily",
    templateSheetName: templateSheetName || "",
    frequency: frequency || "scheduled",
    interval: interval || "",
    folderName: folderName || "",
    tabName: tabName || "",
    columnsToExport: columnsToExport || [],
  });

  const [selectOpen, setSelectOpen] = useState<string>("");

  const handleSettingsChange = (e: SelectChangeEvent<string | string[]>, key: string) => {
    // const isAllSelected = (arr: string[]) => arr.includes("all");
    // const allUserIds = users.map(user => user.uuid);
  };

  const getValue = (value: string | string[]) => {
    //   if (Array.isArray(value)) {
    //     if (isAllSelected(value)) {
    //       return value.length === users.length + 1 ? [] : allUserIds;
    //     } else {
    //       return value.filter(val => users.some(user => user.uuid === val));
    //     }
    //   } else if (value === "all") {
    //     return allUserIds;
    //   } else {
    //     return value;
    //   }
    // };
    // let value = getValue(e.target.value);
    // let newSettings = { ...settings, [key]: value };
    // if (key === "taskPreparerAutomatic" && value === "automatic") {
    //   newSettings = { ...newSettings, taskPreparer: [] };
    // }
    // if (key === "taskReviewerAutomatic" && value === "automatic") {
    //   newSettings = { ...newSettings, taskReviewer: [] };
    // }
    // setSettings(newSettings);
    // const newNodes = nodes.map(item =>
    //   item.id === id ? { ...item, data: { ...item.data, exportSettings: newSettings } } : item
    // );
    // setNodes(newNodes);
  };

  const handleClearField = field => handleSettingsChange({ target: { value: [] } } as any, field);

  const isClickOnClear = target => {
    const clearList = ["svg", "path", "button"];
    if (clearList.includes(target.nodeName)) {
      return true;
    }
    if (target.parent && target.parent.classList.contains("clearField")) {
      return true;
    }
    if (target && target.classList.contains("clearField")) {
      return true;
    }
    return false;
  };

  return (
    <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
      <Typography fontSize="18px" fontWeight="700">
        Export data to Google Sheet
      </Typography>
      <Box>
        <Box sx={{ mt: 3, mb: 2 }}>
          <Typography fontWeight="700">Settings</Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography>Export data as</Typography>
            <Tooltip title="Each export task requires assignment to a specific month. For weekly exports, if the first or last week of the month spans two months, decide whether the task belongs to the month of the first day or the last day of the week. Daily and monthly exports determine the month based on the transaction date range.">
              <InfoIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 0.40)" }} />
            </Tooltip>
          </Box>

          <FormControl>
            <Select
              size={"small"}
              id="period"
              sx={{
                width: "240px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "& fieldset": {
                  outline: "none",
                  border: "none",
                },
              }}
              value={exportSettings.exportAs}
              onChange={e => handleSettingsChange(e, "exportAs")}
            >
              <MenuItem value={"daily"}>Daily</MenuItem>
              <MenuItem value={"weekly"}>Weekly</MenuItem>
              <MenuItem value={"monthly"}>Monthly</MenuItem>
              <MenuItem value={"quarterly"}>Quarterly</MenuItem>
              <MenuItem value={"yearly"}>Yearly</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Box sx={{ display: "flex" }}>
            <Typography>Template Sheet name</Typography>
            <Tooltip title="Each export task requires assignment to a specific month. For weekly exports, if the first or last week of the month spans two months, decide whether the task belongs to the month of the first day or the last day of the week. Daily and monthly exports determine the month based on the transaction date range.">
              <InfoIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 0.40)" }} />
            </Tooltip>
          </Box>
          <FormControl>
            <Select
              size={"small"}
              id="period"
              sx={{
                width: "240px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "& fieldset": {
                  outline: "none",
                  border: "none",
                },
              }}
              value={exportSettings.templateSheetName || ""}
              onChange={e => handleSettingsChange(e, "templateSheetName")}
            >
              <MenuItem value={"lastDay"}>Based on the last day of the week</MenuItem>
              <MenuItem value={"option2"}>Option 2</MenuItem>
              <MenuItem value={"option3"}>Option 3</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ my: 2 }}>
          <Divider />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography>Frequency</Typography>
          </Box>
          <FormControl>
            <RadioGroup
              row
              sx={{
                width: "240px",
              }}
              defaultValue="automatic"
              name="task-preparer-buttons-group"
              value={settings.tabName}
              onChange={e => handleSettingsChange(e, "tabName")}
            >
              <FormControlLabel value="scheduled" control={<Radio />} label="Automatic" />
              <FormControlLabel value="oneTime" control={<Radio />} label="Manual" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography>Interval</Typography>
          <FormControl>
            <Select
              size={"small"}
              id="period"
              sx={{
                width: "240px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "& fieldset": {
                  outline: "none",
                  border: "none",
                },
              }}
              value={exportSettings.interval}
              onChange={e => handleSettingsChange(e, "interval")}
            >
              <MenuItem value={"1001"}>1001 Debit Account Name</MenuItem>
              <MenuItem value={"1002"}>1002 Debit Account Name</MenuItem>
              <MenuItem value={"1003"}>1003 Debit Account Name</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography>Folder in google drive</Typography>
          <FormControl>
            <Select
              size={"small"}
              id="period"
              autoWidth={true}
              sx={{
                width: "240px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "& fieldset": {
                  outline: "none",
                  border: "none",
                },
              }}
              value={exportSettings.folderName}
              onChange={e => handleSettingsChange(e, "folderName")}
            >
              <MenuItem value={"1001"}>1001 Credit Account Name</MenuItem>
              <MenuItem value={"1002"}>1002 Credit Account Name</MenuItem>
              <MenuItem value={"1003"}>1003 Credit Account Name</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box sx={{ my: 2 }}>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography fontWeight="700">Data</Typography>
            <Tooltip title="Transactions will be exported once two approval signatures are given to a task, ensuring the four-eye principle is applied to prevent mistakes.">
              <InfoIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 0.40)" }} />
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography>Tab name inside the file</Typography>
          <FormControl>
            <Select
              size={"small"}
              id="period"
              sx={{
                width: "240px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "& fieldset": {
                  outline: "none",
                  border: "none",
                },
              }}
              value={exportSettings.frequency}
              onChange={e => handleSettingsChange(e, "frequency")}
            >
              <MenuItem value={"pspData"}>PSP Data</MenuItem>
              <MenuItem value={"boData"}>BO Data</MenuItem>
              <MenuItem value={"option3"}>Option 3</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography>Columns to export</Typography>
          <InputSelectMulti
            sx={{
              width: "240px",
            }}
            valueKey={"email"}
            // disabled={settings.taskPreparerAutomatic === "automatic"}
            value={exportSettings.columnsToExport || []}
            onChange={e => handleSettingsChange(e as SelectChangeEvent<string[]>, "taskPreparer")}
            onChangeArgument={"columnsToExport"}
            truncateLength={20}
            disableUnderline={true}
            open={selectOpen === "columnsToExport"}
            allValues={[]}
            // label={preparerLabel}
            // emptyText={emptyPreparerLabel}
            onOpen={e => {
              const isClear = isClickOnClear(e.target);
              if (isClear) {
                handleClearField("columnsToExport");
              } else {
                setSelectOpen("columnsToExport");
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ExportGoogleSheet;
