import { NodeProps } from "@xyflow/react";
import { useShallow } from "zustand/react/shallow";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import { createEdge } from "../utils/edges";

const selector = createRFSelector(
  "getNode",
  "nodes",
  "edges",
  "setNodes",
  "setEdges",
  "getIncomers",
  "getOutgoers"
);

export function useDeleteFilterClick(id: NodeProps["id"]) {
  const { getNode, setNodes, setEdges, nodes, edges, getIncomers, getOutgoers } = useStore(
    useShallow(selector)
  );

  const onClick = () => {
    const currentNode = getNode(id);

    if (!currentNode) {
      return;
    }

    const currentParent = currentNode.parentId;

    const currentNodeIndex = nodes.findIndex(node => node.id === id);
    const newNodes = nodes
      .map((node, index) => {
        if (node.type === "groupNode" && node.id === currentParent) {
          return {
            ...node,
            height: (node.measured.height as number) - currentNode.measured.height - 25,
            style: {
              ...node.style,
            },
          };
        }
        if (index >= currentNodeIndex && node.parentId === currentParent) {
          return {
            ...node,
            position: {
              x: node.position.x,
              y: node.position.y - currentNode.measured.height - 25,
            },
          };
        }
        return node;
      })
      .filter((node, index) => {
        if (node.id === currentNode.id) return false;
        if (index >= currentNodeIndex - 1 && node.parentId !== currentParent) {
          return false;
        }
        return true;
      });

    const incomers = getIncomers(currentNode);
    const outgoers = getOutgoers(currentNode).filter(node => node.parentId === currentParent);
    const newEdge = createEdge(
      incomers[0].id,
      outgoers[0].id,
      "groupContinuationSource",
      "groupContinuationTarget"
    );

    const newEdges = edges
      .filter(
        edge =>
          newNodes.some(node => node.id === edge.source) &&
          newNodes.some(node => node.id === edge.target)
      )
      .concat([newEdge]);

    setNodes(newNodes);
    setEdges(newEdges);
  };

  return onClick;
}

export default useDeleteFilterClick;
