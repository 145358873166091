import { Node, NodeProps } from "@xyflow/react";
import { useCallback } from "react";

import { useShallow } from "zustand/react/shallow";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import { createEdge } from "../utils/edges";
import {
  createSecondaryFilterNode,
  GAP_BETWEEN_NODES,
  GROUPED_NODE_PADDING_POSITION,
} from "../utils/nodes";

const selector = createRFSelector(
  "nodes",
  "edges",
  "setNodes",
  "setEdges",
  "getIncomers",
  "getNode",
  "getOutgoers"
);

type NodePosition = {
  x: number;
  y: number;
};

export function useAddFilterClick(id: NodeProps["id"]) {
  const { nodes, edges, setNodes, setEdges, getIncomers, getNode, getOutgoers } = useStore(
    useShallow(selector)
  );

  const onClick = useCallback(() => {
    const currentNode: Node = getNode(id);

    if (!currentNode) {
      return;
    }
    const currentParent: string = currentNode.parentId;
    const filterTypes = ["filterNodeSecondary", "filterNodeRemaining"];

    const outgoingNodes: Node[] = getOutgoers(currentNode);
    const outGoingInSameGroup = outgoingNodes.find(node => node.parentId === currentParent);
    let outgoingTxNode: Node = outgoingNodes.find(
      node => filterTypes.includes(node.type) && node.id !== id
    );

    const remainingTxNode: Node = nodes.find(
      node => node.type === "filterNodeRemaining" && node.parentId === currentParent
    );

    const addNewFilterIndex: number = nodes.findIndex(node => node.id === outGoingInSameGroup.id);
    const currentNodeIndex: number = nodes.findIndex(node => node.id === currentNode.id);

    // Create new nodes
    const newFilterNodePosition: NodePosition = {
      x: GROUPED_NODE_PADDING_POSITION,
      y: currentNode.position.y + currentNode.initialHeight + GAP_BETWEEN_NODES,
    };
    const newFilterNode = createSecondaryFilterNode(currentParent, newFilterNodePosition);

    // Create edges
    const rootAddFilterEdge = createEdge(
      currentNode.id,
      newFilterNode.id,
      "groupContinuationSource",
      "groupContinuationTarget"
    );
    const newFilterNodeEdge = createEdge(
      newFilterNode.id,
      outgoingTxNode.id,
      "groupContinuationSource",
      "groupContinuationTarget"
    );

    // Add new edges
    const newEdges = edges
      .filter(edge => edge.source !== currentNode.id)
      .concat([rootAddFilterEdge, newFilterNodeEdge]);

    const totalHeight = newFilterNode.initialHeight + GAP_BETWEEN_NODES;

    let newNodes = [...nodes];
    newNodes.splice(addNewFilterIndex, 0, newFilterNode);

    // Remove the existing filterNodeRemaining for this parent
    newNodes = newNodes.filter(
      node => !(node.type === "filterNodeRemaining" && node.parentId === currentParent)
    );

    const currentGroup = newNodes.filter(node => node.parentId === currentParent);
    const lastNode = currentGroup[currentGroup.length - 1];
    const updatedNodes = [...newNodes];

    const MAGIC_NUMBER = 1; // 2 after the current node
    for (let i = currentNodeIndex + MAGIC_NUMBER; i < updatedNodes.length; i++) {
      const node: Node = updatedNodes[i];
      const nodeOutgoers = getOutgoers(node);

      if (node.parentId === currentParent) {
        if (nodeOutgoers.length > 0 && nodeOutgoers[0].parentId !== currentParent) {
          nodeOutgoers[0].position.y += totalHeight;
        }
      }
    }

    updatedNodes.push(remainingTxNode);

    const finalNodes = updatedNodes.map(node => {
      if (node.type === "groupNode" && node.id === currentParent) {
        return {
          ...node,
          height: (node.measured.height as number) + totalHeight,

          style: {
            ...node.style,
            height: (node.measured.height as number) + totalHeight,
          },
        };
      }
      if (node.id === remainingTxNode.id) {
        return {
          ...node,
          position: {
            x: GROUPED_NODE_PADDING_POSITION,
            y: lastNode.position.y + lastNode.initialHeight + GAP_BETWEEN_NODES,
          },
        };
      }
      return node;
    });

    setNodes(finalNodes);

    setEdges(newEdges);
  }, [nodes, edges, getNode, id, setEdges, setNodes]);

  return onClick;
}

export default useAddFilterClick;
