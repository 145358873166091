import { Box } from "@mui/material";
import { createPortal } from "react-dom";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import { useShallow } from "zustand/react/shallow";

const selector = createRFSelector("updateAndRunWorkflow");
const Sidebar = props => {
  const { openHook, children = null } = props;
  const { open, setOpen } = openHook;

  const { updateAndRunWorkflow } = useStore(useShallow(selector));

  if (!open) return null;

  const PortalContent = () => {
    return (
      <Box
        sx={{
          width: "calc(100% - 64px)",
          height: "100vh",
          zIndex: 4,
          top: 0,
          position: "absolute",
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 464px)",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
            cursor: "pointer",
          }}
          onClick={async () => {
            // updateAndRunWorkflow();
            setOpen(false);
          }}
        />
        <Box
          sx={{
            backgroundColor: "white",
            width: "464px",
            padding: "24px 40px 24px 24px",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            overflow: "scroll",
          }}
        >
          {children}
        </Box>
      </Box>
    );
  };

  return createPortal(<PortalContent />, document.getElementById("workflows-container"));
};

export default Sidebar;
