import React, { useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import TaskTable from "./components/TasksTable";
import TasksTableSidebar from "./components/TasksTableSidebar";
import { TASK_TYPE } from "./constants";
import { useSelectedMonthTaskType } from "./hooks/useSelectedMonthTaskType";
import { useTask } from "./hooks/useTask";

const TasksView: React.FC = () => {
  const { tasks, isLoading, error, fetchTasks } = useTask();
  const { selectedMonthTaskType, setSelectedMonth, setSelectedTaskType } =
    useSelectedMonthTaskType();

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  const taskCounts = useMemo(() => {
    return tasks.reduce(
      (acc, task) => {
        const taskMonth = dayjs(task.created_at).format("MMMM YYYY");
        if (!acc[taskMonth]) {
          acc[taskMonth] = { Exports: 0 };
        }
        if (task.type === TASK_TYPE.EXPORT) {
          acc[taskMonth].Exports += 1;
        }
        return acc;
      },
      {} as Record<string, { Exports: number }>
    );
  }, [tasks]);

  const filteredTasks = useMemo(() => {
    return tasks.filter(
      task =>
        dayjs(task.created_at).format("MMMM YYYY") === selectedMonthTaskType.month &&
        task.type === selectedMonthTaskType.taskType
    );
  }, [tasks, selectedMonthTaskType]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ width: 250, flexShrink: 0 }}>
          <TasksTableSidebar
            onMonthChange={setSelectedMonth}
            onTaskTypeChange={setSelectedTaskType}
            selectedMonthTaskType={selectedMonthTaskType}
            taskCounts={taskCounts}
          />
        </Box>
        <Box sx={{ flexGrow: 1, overflow: "auto", ml: 2 }}>
          <TaskTable tasks={filteredTasks} selectedMonth={selectedMonthTaskType.month} />
        </Box>
      </Box>
    </Box>
  );
};

export default TasksView;
