import { Box, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as WorkflowIcon } from "../../../assets/icons/workflowIcon.svg";
import { useBoApiClient } from "../../../hooks/useBoApiClient";
import DeleteWorkflowDialog from "../../../components/Dialogs/Workflows/DeleteWorkflowDialog";
import EditWorkflowNameDialog from "../../../components/Dialogs/Workflows/EditWorkflowNameDialog";

const WorkflowsView = ({ workflowHook }) => {
  const { workflows, fetchWorkflows } = workflowHook;
  const [menuOpenId, setMenuOpenId] = useState<string | null>(null);
  const [hiddenId, setHiddenId] = useState<string>("");
  const [deleteWorkflowOpen, setDeleteWorkflowOpen] = useState<boolean>(false);
  const [editWorkflowOpen, setEditWorkflowOpen] = useState<boolean>(false);

  const boApiClient = useBoApiClient();

  const handleMenuOpen = (id: string) => {
    setMenuOpenId(id);
  };

  useEffect(() => {
    fetchWorkflows();
  }, []);

  const handleMenuClose = () => {
    setMenuOpenId(null);
  };

  return (
    <Box>
      <Box
        sx={{
          width: "calc(100vw - 64px)",
          height: "calc(100vh - 64px)",
          display: "flex",
          justifyContent: "center",
          pt: 2,
        }}
      >
        {deleteWorkflowOpen && (
          <DeleteWorkflowDialog
            success={async () => {
              try {
                await boApiClient.workflows.deleteWorkflow(menuOpenId as string);
              } catch (e) {
              } finally {
                setDeleteWorkflowOpen(false);
                setMenuOpenId("");
                fetchWorkflows();
              }
            }}
            cancel={() => {
              setDeleteWorkflowOpen(false);
              setMenuOpenId("");
            }}
          />
        )}
        {editWorkflowOpen && (
          <EditWorkflowNameDialog
            success={async (data: string) => {
              try {
                const workflow = workflows.find(workflow => workflow.id === menuOpenId);
                await boApiClient.workflows.updateWorkflow(menuOpenId, {
                  definition: workflow?.definition,
                  name: data,
                });
              } catch (e) {
              } finally {
                fetchWorkflows();
                setEditWorkflowOpen(false);
                setMenuOpenId("");
              }
            }}
            cancel={() => {
              setEditWorkflowOpen(false);
              setMenuOpenId("");
            }}
            workflowName={workflows.find(workflow => workflow.id === menuOpenId)?.name}
          />
        )}
        <Box sx={{ width: "720px" }}>
          <Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", p: "8px 8px 7px 8px" }}>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.60)">
              Workflows
            </Typography>
          </Box>

          <Box>
            {workflows.map(workflow => (
              <Box
                key={workflow.id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "4fr 1fr",
                  justifyContent: "space-between",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  gap: 2,
                  p: "8px 8px 7px 8px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
                onMouseEnter={() => setHiddenId(workflow.id)}
                onMouseLeave={() => setHiddenId("")}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Box
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      p: 0.5,
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <WorkflowIcon sx={{ width: "32px", height: "32px" }} />
                  </Box>
                  <Box>
                    <Typography variant="body2">{workflow.name}</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box>
                    <Link
                      style={{
                        visibility: workflow.id === hiddenId ? "visible" : "hidden",
                      }}
                      target="_blank"
                      to={`/app/tasks?workflow=${workflow.id}`}
                    >
                      <Button size="small">View</Button>
                    </Link>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => handleMenuOpen(workflow.id)}
                      id={`menu-button-${workflow.id}`}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      open={menuOpenId === workflow.id}
                      anchorEl={document.getElementById(`menu-button-${workflow.id}`)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => setEditWorkflowOpen(true)}>Rename</MenuItem>
                      <MenuItem
                        sx={{ color: "#871E1A" }}
                        onClick={() => setDeleteWorkflowOpen(true)}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkflowsView;
