import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import InputSelectMulti from "../../../../components/UI/Input/InputSelectMulti";
import Radio from "../../../../components/UI/Radio/Radio";
import { useBoApiClient } from "../../../../hooks/useBoApiClient";
import { ConnectionDto, GetUserDto, XeroTenantDto } from "../../../../spec/bo";
import useStore, { createRFSelector } from "../store/ReactflowStore";
import { useShallow } from "zustand/react/shallow";

interface IExportProps {
  id: string;
  integration: ConnectionDto | null;
  data: {
    exportSettings: IExportSettings;
  };
  users: GetUserDto[];
  organizations: XeroTenantDto[];
}
export interface IExportSettings {
  period: "daily" | "weekly" | "monthly" | "quarterly" | "yearly";
  preferredSource: "primary" | "secondary";
  taskMonthAssignment: "lastDay" | "firstDay";
  debitAccount: string;
  creditAccount: string;
  taskPreparers: string[];
  taskPreparerType: string;
  taskReviewers: string[];
  taskReviewerType: string;
  organizationId: string;
}

const selector = createRFSelector("nodes", "setNodes");

const Export = (props: IExportProps) => {
  const { id, data, users, organizations } = props;
  const { exportSettings } = data;
  const {
    period = "monthly",
    debitAccount = "",
    creditAccount = "",
    taskPreparers = [],
    taskPreparerType = "automatic",
    taskReviewers = [],
    taskReviewerType = "manual",
    preferredSource = "primary",
    taskMonthAssignment = "lastDay",
    organizationId = "",
  } = exportSettings;
  const { nodes, setNodes } = useStore(useShallow(selector));
  const boApiClient = useBoApiClient();

  const [loading, setLoading] = useState<boolean>(false);

  const [settings, setSettings] = useState<IExportSettings>({
    period: period || "monthly",
    debitAccount: debitAccount || "",
    creditAccount: creditAccount || "",
    taskMonthAssignment: taskMonthAssignment || "lastDay",
    taskPreparers: taskPreparers || [],
    taskPreparerType: taskPreparerType || "automatic",
    taskReviewers: taskReviewers || [],
    taskReviewerType: taskReviewerType || "manual",
    preferredSource: preferredSource || "primary",
    organizationId: organizationId || "",
  });

  const [selectOpen, setSelectOpen] = useState<string>("");

  const possibleOrganizations = organizations.find(item => item.id === settings.organizationId);

  const preparerLabel = taskPreparerType === "automatic" ? "Automatic" : "Preparer";
  const emptyPreparerLabel =
    taskPreparerType === "automatic" ? "Automatic" : "No preparer assigned";
  const reviewerLabel = taskReviewerType === "automatic" ? "Automatic" : "Reviewer";
  const emptyReviewerLabel =
    taskReviewerType === "automatic" ? "Automatic" : "No reviewer assigned";

  const handleSettingsChange = (e: SelectChangeEvent<string | string[]>, key: string) => {
    const isAllSelected = (arr: string[]) => arr.includes("all");
    const allUserIds = users.map(user => user.uuid);

    const getValue = (value: string | string[]) => {
      if (Array.isArray(value)) {
        if (isAllSelected(value)) {
          return value.length === users.length + 1 ? [] : allUserIds;
        } else {
          return value.filter(val => users.some(user => user.uuid === val));
        }
      } else if (value === "all") {
        return allUserIds;
      } else {
        return value;
      }
    };

    let value = getValue(e.target.value);
    let newSettings = { ...settings, [key]: value };

    if (key === "taskPreparerType" && value === "automatic") {
      newSettings = { ...newSettings, taskPreparers: [] };
    }
    if (key === "taskReviewerType" && value === "automatic") {
      newSettings = { ...newSettings, taskReviewers: [] };
    }

    setSettings(newSettings);

    const newNodes = nodes.map(item =>
      item.id === id ? { ...item, data: { ...item.data, exportSettings: newSettings } } : item
    );

    setNodes(newNodes);
  };

  const handleClearField = field => handleSettingsChange({ target: { value: [] } } as any, field);

  const isClickOnClear = target => {
    const clearList = ["svg", "path", "button"];
    if (clearList.includes(target.nodeName)) {
      return true;
    }
    if (target.parent && target.parent.classList.contains("clearField")) {
      return true;
    }
    if (target && target.classList.contains("clearField")) {
      return true;
    }
    return false;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography fontSize="18px" fontWeight="700">
        Export to accounting
      </Typography>
      <Box>
        <Box sx={{ mt: 3, mb: 2 }}>
          <Typography fontWeight="700">Export settings</Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography>Export task creation interval</Typography>

          <FormControl>
            <Select
              size={"small"}
              id="period"
              sx={{
                width: "240px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "& fieldset": {
                  outline: "none",
                  border: "none",
                },
              }}
              value={exportSettings.period}
              onChange={e => handleSettingsChange(e, "period")}
            >
              <MenuItem value={"daily"}>Daily</MenuItem>
              <MenuItem value={"weekly"}>Weekly</MenuItem>
              <MenuItem value={"monthly"}>Monthly</MenuItem>
              <MenuItem value={"quarterly"}>Quarterly</MenuItem>
              <MenuItem value={"yearly"}>Yearly</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography>Month assignment for tasks</Typography>
            <Tooltip title="Each export task requires assignment to a specific month. For weekly exports, if the first or last week of the month spans two months, decide whether the task belongs to the month of the first day or the last day of the week. Daily and monthly exports determine the month based on the transaction date range.">
              <InfoIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 0.40)" }} />
            </Tooltip>
          </Box>
          <FormControl>
            <Select
              size={"small"}
              id="period"
              sx={{
                width: "240px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "& fieldset": {
                  outline: "none",
                  border: "none",
                },
              }}
              value={exportSettings.taskMonthAssignment || ""}
              onChange={e => handleSettingsChange(e, "taskMonthAssignment")}
            >
              <MenuItem value={"lastDay"}>Based on the last day of the week</MenuItem>
              <MenuItem value={"firstDay"}>Based on the first day of the week</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography>Preferred source</Typography>
            <Tooltip title="Each export task requires assignment to a specific month. For weekly exports, if the first or last week of the month spans two months, decide whether the task belongs to the month of the first day or the last day of the week. Daily and monthly exports determine the month based on the transaction date range.">
              <InfoIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 0.40)" }} />
            </Tooltip>
          </Box>
          <FormControl>
            <Select
              size={"small"}
              id="period"
              sx={{
                width: "240px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "& fieldset": {
                  outline: "none",
                  border: "none",
                },
              }}
              value={exportSettings.preferredSource}
              onChange={e => handleSettingsChange(e, "preferredSource")}
            >
              <MenuItem value={"primary"}>Primary transactions</MenuItem>
              <MenuItem value={"secondary"}>Secondary transactions</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography>Organization</Typography>
          <FormControl>
            <Select
              size={"small"}
              id="period"
              sx={{
                width: "240px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "& fieldset": {
                  outline: "none",
                  border: "none",
                },
              }}
              disabled={loading}
              value={exportSettings.organizationId}
              onChange={e => handleSettingsChange(e, "organizationId")}
            >
              {organizations.map(item => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography>To debit account</Typography>
          <FormControl>
            <Select
              size={"small"}
              id="period"
              sx={{
                width: "240px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "& fieldset": {
                  outline: "none",
                  border: "none",
                },
              }}
              disabled={loading || !exportSettings.organizationId}
              value={exportSettings.debitAccount}
              onChange={e => handleSettingsChange(e, "debitAccount")}
            >
              {possibleOrganizations?.accounts
                .filter(item => {
                  return item.code !== exportSettings.creditAccount;
                })
                .map(item => {
                  return (
                    <MenuItem key={item.code} value={item.code}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography>To credit account</Typography>
          <FormControl>
            <Select
              size={"small"}
              id="period"
              autoWidth={true}
              sx={{
                width: "240px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "& fieldset": {
                  outline: "none",
                  border: "none",
                },
              }}
              value={exportSettings.creditAccount}
              onChange={e => handleSettingsChange(e, "creditAccount")}
            >
              {possibleOrganizations?.accounts
                .filter(item => {
                  return item.code !== exportSettings.debitAccount;
                })
                .map(item => {
                  return (
                    <MenuItem key={item.code} value={item.code}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box sx={{ mt: 3, mb: 2 }}>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography fontWeight="700">Task signatures</Typography>
            <Tooltip title="Transactions will be exported once two approval signatures are given to a task, ensuring the four-eye principle is applied to prevent mistakes.">
              <InfoIcon fontSize="small" sx={{ color: "rgba(0, 0, 0, 0.40)" }} />
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography>Task preparer</Typography>
          <FormControl>
            <RadioGroup
              row
              sx={{
                width: "240px",
              }}
              defaultValue="automatic"
              name="task-preparer-buttons-group"
              value={settings.taskPreparerType}
              onChange={e => handleSettingsChange(e, "taskPreparerType")}
            >
              <FormControlLabel value="automatic" control={<Radio />} label="Automatic" />
              <FormControlLabel value="manual" control={<Radio />} label="Manual" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography>Assign task preparers</Typography>

          <InputSelectMulti
            sx={{
              width: "240px",
            }}
            valueKey={"email"}
            disabled={settings.taskPreparerType === "automatic"}
            value={exportSettings.taskPreparers || []}
            onChange={e => handleSettingsChange(e as SelectChangeEvent<string[]>, "taskPreparers")}
            onChangeArgument={"taskPreparers"}
            truncateLength={20}
            disableUnderline={true}
            open={selectOpen === "taskPreparers"}
            allValues={users}
            label={preparerLabel}
            emptyText={emptyPreparerLabel}
            onOpen={e => {
              const isClear = isClickOnClear(e.target);
              if (isClear) {
                handleClearField("taskPreparers");
              } else {
                setSelectOpen("taskPreparers");
              }
            }}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography>Task reviewer</Typography>
          <FormControl>
            <RadioGroup
              row
              sx={{
                width: "240px",
              }}
              defaultValue="manual"
              name="task-reviewer-buttons-group"
              value={settings.taskReviewerType}
              onChange={e => handleSettingsChange(e, "taskReviewerType")}
            >
              <FormControlLabel value="automatic" disabled control={<Radio />} label="Automatic" />
              <FormControlLabel value="manual" control={<Radio />} label="Manual" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography>Assign task reviewers</Typography>
          <InputSelectMulti
            sx={{
              width: "240px",
            }}
            valueKey={"email"}
            disabled={settings.taskReviewerType === "automatic"}
            value={exportSettings.taskReviewers || []}
            onChange={e => handleSettingsChange(e as SelectChangeEvent<string[]>, "taskReviewers")}
            onChangeArgument={"taskReviewers"}
            truncateLength={20}
            open={selectOpen === "taskReviewers"}
            allValues={users}
            label={reviewerLabel}
            emptyText={emptyReviewerLabel}
            onOpen={e => {
              const isClear = isClickOnClear(e.target);
              if (isClear) {
                handleClearField("taskReviewers");
              } else {
                setSelectOpen("taskReviewers");
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Export;
