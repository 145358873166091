import { Box } from "@mui/material";
import { styled } from "@mui/system";

const DotSeparator = styled(Box)(({ theme }) => ({
  display: "inline-block",
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  backgroundColor: theme.palette.grey[500],
  margin: "0 16px",
}));

export default DotSeparator;
